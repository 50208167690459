<template>
	<div class="position-relative flex-column justify-center empty">
		<slot
			name="body"
		>
			<div
				class="text-center character3_none pt-130 size-px-16 color-gray"
				:inner-html.prop="text ? text : '조회된 내역이 없습니다.'"
			></div>
		</slot>
	</div>
</template>
<script>
	export default {
		name: 'Empty'
		, props: ['text']
	}
</script>

<style>
	.bg-tv .empty div{
		color: var(--gray01)
	}
</style>