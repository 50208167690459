<template>
	<div
	>
		<VideoJs
			v-if="item_video.video.src"
			:item="item_video"
			:id="item.bulletin_number + t"
			class="full-height width-100"
			:controls="true"
			:custom="true"
			:pause="is_pause"
			:play="is_play"
			:is_video="false"
			:is_auto="false"
			:is_sample="is_sample"
		></VideoJs>
	</div>
</template>

<script>

import VideoJs from "@/components/VideoJs";

export default {
	name: 'CartelBbsTv'
	, props: ['user', 'item', 'index', 'is_on', 'paused', 'is_sample']
	, components: {VideoJs}
	, data: function(){
		return {
			items: []
			, player: ''
			, videoOptions: {

			}
			, item_cover: {}
			, item_video: {
				video: {
					src: ''
				}
				, cover: {
					src: ''
				}
			}
			, is_cover_off: false
			, is_scroll: false
			, timeout: ''
			, is_pause: false
			, is_play: false
			, t: ('' + Math.random()).replace('0.', '')
		}
	}
	, methods: {
	}

	, async created() {

		for(let i = 0; i < this.item.post_file_list.length; i++) {
			let file = this.item.post_file_list[i]

			if (file.post_appendix_file_div_code == 'CA01000002') {
				file.post_appendix_file_change_before_indexnumber = 1
				file.src = file.post_appendix_file_org_url
				this.item_video.video = file

			} else {
				file.src = file.post_appendix_file_org_url
				file.post_appendix_file_change_before_indexnumber = 2
				this.item_video.cover = file
				this.cover_img_url = file.post_appendix_file_org_url
				this.origin_img_index = file.post_appendix_file_indexnumber
			}
		}

	}
	, watch: {
		is_on: {
			handler: function(call){
				console.log('is_on', call)
				this.is_play = call
				this.is_pause = !call
			}
		}
		, paused: {
			handler: function(call){
				console.log('paused', call)
				if(call){
					this.is_play = !call
					this.is_pause = call
				}
			}
		}
	}
}
</script>

<style>

.wrap-div div{
	width: 100%; height: 100%;
}
.wrap-div video {
	width: 100%; height: 100%;
}

.on {
	z-index: 2;
}
.off {
	z-index: 0;
}


.in-hover .pause{
	opacity: 0;
	transition: opacity 1s 1s ease
}
</style>