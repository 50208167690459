<template>
	<div class="bg_wh">
		<div class="header filter">
			<div class="top top_wh">
				<div class="util">
					<button
						@click="goBack"
						class="arrow"
					>
						<i class="icon icon-arrow-left"></i>
					</button>
				</div>
				<div class="header_search">
					<input
						v-model="item_search.search_value"
						type="text" :placeholder="$language.common.input_search_keyword"
						maxlength="50"
						@keyup.enter="getCartelBbs"
					>
				</div>
				<div class="header_search_btn">
					<div
						v-if="item_search.search_value"
						class="btn_del"
					>
						<!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
						<button
							class="active"
							@click="item_search.search_value = ''"
						><span class="hide">{{ $language.common.close }}</span></button>
					</div>
					<div class="util">
						<button
							class="img_button"
							@click="getCartelBbs"
						><span class="hide">{{ $language.common.search }}</span></button>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="!$route.params.code"
			class="cartel_cover"
		>
			<div class="cover_background">
				<img
					v-if="item_cartel.cartl_img_url"
					:src="item_cartel.cartl_img_url" alt="register cover image"
				>
			</div>
			<div class="cover_content clear">
				<div class="title_area">
					<h2>{{  item_cartel.cartl_name }}</h2>
				</div>
				<div class="cartel_area clear">
					<div class="badge_area">
						<span class="badge_30 " :class="'badge_30_' + item_cartel.type + '_bl'">
							<em class="hide">{{  item_cartel.type }}</em>
						</span>
					</div>
					<div class="text_area">
						<div class="level">{{ item_cartel.cartl_level_name }}</div>
						<div class="member">
							멤버수<b>{{  item_cartel.current_member_count }}</b>
						</div>
					</div>
				</div>
				<div class="cartel_btn_area">
					<a
						v-if="is_join_confirm"
						@click="$emit('to', { name: 'mafia057', params: { idx: $route.params.idx }})"
					>
						<div
							class="m_invite"
						>
							<span>{{ $language.common.invite_member }}</span>
						</div>
					</a>
					<a
						v-else-if="is_join_wait"
					>
						<div
							class="btn_s btn_fill_blue"
						>
							<span>{{  item_cartel.cartl_entry_state_name }}</span>
						</div>
					</a>
					<a
						v-else-if="is_join_cancel"
					>
						<div
							class="btn_s btn_fill_gray"
						>
							<span>{{  item_cartel.cartl_entry_state_name }}</span>
						</div>
					</a>
				</div>
			</div>
		</div>

		<div
			v-if="!$route.params.code"
			class="c_notice"
		>
			<div class="container">
				<div class="row">
					<h3 @click="$emit('to', { name: 'mafia127', params: { idx: $route.params.idx, code: $language.common.bbs_code_notice }})" class="tit">{{ $language.common.notice }}</h3>
					<div class="c_notice_list">
						<ul>
							<template
								v-if="items_cartel_notice.length > 0"
							>
								<li
									v-for="(notice, index) in items_cartel_notice"
									:key="'notice_' + index"

									@click="$emit('to', { name: 'mafia058', params: { idx: $route.params.idx, code: notice.board_type_code, b_id: notice.board_number, bbs_id: notice.bulletin_number}})"
								>
									<em>공지</em>
									<span>{{  notice.post_title }}</span>
								</li>
							</template>
							<li
								v-else
							>
								<em>{{ $language.common.announcement }}</em>
								<span>no data</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="mt-50">
			<CartelBbsList
				:user="user"
				:items_list="items_cartel_bbs"
				:item_search="item_search"
				:item_cartel="item_cartel"
				:from="'mafia0531'"
			></CartelBbsList>
		</div>

		<div
			v-if="is_join"
			class="cartel_join"
		>
			<button @click="toJoin">
				<div class="cartel_join_btn">
					<span>{{ $language.common.cartel }}<br><em>{{ $language.common.join }}</em></span>

				</div>
			</button>
		</div>

		<div
			v-if="is_mine"
			class="cartel_write"
		>
			<button
				@click="$emit('to', {name: 'mafia1271', params: {idx: $route.params.idx, code: $route.params.code, b_id: $route.params.b_id}})"
			>
				<div class="cartel_write_btn">
					<span class="hide">{{ $language.word.write }}</span>
				</div>
			</button>
		</div>

		<mafia098
			v-if="is_098"
			:cartel="item_cartel"
			:user="user"
			:friend="item_cartel"

			@cancel="is_098 = false"
			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 9999; background-color: white"
		></mafia098>

		<mafia064
			v-if="is_more"

			:user="user"
			:item_bbs="item_more"
			@cancel="is_more = false"
			@deleteBbs="deleteBbs"
			style="z-index: 1003"
		>
		</mafia064>

		<popup_confirm
			v-if="is_delete"

			@click="deleteBbs"
			@cancel="is_delete = false"
		>
			<template
				v-slot:title
			>{{ $language.notice.article_delete }}</template>
			<template
				v-slot:main-txt
			>{{ $language.notice.article_delete_message }}</template>
			<template
				v-slot:sub-txt
			>{{ $language.notice.article_delete_message_confirm }}</template>
		</popup_confirm>
	</div>
</template>


<script>

import mafia064 from "@/view/Cartel/mafia064";
import popup_confirm from "@/view/Layout/PopupConfirm";
import mafia098 from '@/view/Cartel/mafia098'
import CartelBbsList from "@/view/Cartel/CartelBbsList";

export default {
	name: 'mafia049'
	, props: ['user']
	, components: {CartelBbsList, mafia064, popup_confirm, mafia098}
	, data: function(){
		return {
			program: {
				name: 'cartel'
				, title: this.$language.common.cartel
        , type: 'cartel'
				, not_header: true
				, not_footer: true
			}
			, item_cartel: {}
			, items_cartel: []
			, items_cartel_notice: []
			, items_cartel_bbs: []
			, idx_cartel: this.$route.params.idx
			, item_my_cartel: {}
			, is_more: false
			, item_more: {}
			, is_trans: true
			, item_board_config: {}
			, is_delete: false
			, is_098: false
			, icons_emoji: [

        { code: 'CA01100001', type: 'best', txt: this.$language.emoticon.best}
        , { code: 'CA01100002', type: 'funny', txt: this.$language.emoticon.funny}
        , { code: 'CA01100003', type: 'like', txt: this.$language.emoticon.like}
        , { code: 'CA01100004', type: 'sad', txt: this.$language.emoticon.sad}
        , { code: 'CA01100005', type: 'surprise', txt: this.$language.emoticon.surprise}
        , { code: 'CA01100006', type: 'angry', txt: this.$language.emoticon.angry}
			]
			, item_search: this.$storage.init({
				search_value: ''
				, page_number: this.$language.base.page_number
				, pagerecnum: this.$language.base.pagerecnum
			})
			, type: 'list'
		}
	}
	, computed: {
		is_mine: function(){
			let t = false
			if(this.is_join_confirm){
				t = true
			}
			return t
		}
		, text_empty: function (){
			let t = this.$language.common.error_empty_search_value
			if(this.item_search.search_value && this.items_cartel_bbs){
				t = this.$language.common.NotFoundArticle
			}
			return t
		}
		, list_bbs: function(){
			let self = this
			return this.items_cartel_bbs.filter(function(item){
				if(item.blok_member_fg == 'Y'){
					item.is_block = true
				}
				if(item.sympaty_icon_code != ''){
					item.is_empathy = true
				}
				item.time_t = self.$date.getTimeStory(item.registdate)
				return item
			})
		}
		, is_join: function(){
			let t = false
			if(this.item_cartel.cartl_entry_state_code == ''){
				t = true
			}
			return t
		}
		, is_join_confirm: function(){
			let t = false
			switch(this.item_cartel.cartl_entry_state_code) {
				case 'CA00300004':
					t = true
					break
			}
			return t
		}
		, is_join_cancel: function (){
			let t = false
			switch(this.item_cartel.cartl_entry_state_code){
				case 'CA00300003':
				case 'CA00300005':
				case 'CA00300006':
				case 'CA00300007':
					t = true
					break;
			}
			return t
		}
		, is_join_wait: function(){
			let t = false
			switch(this.item_cartel.cartl_entry_state_code){
				case 'CA00300001':
				case 'CA00300002':
					t = true
					break;
			}
			return t
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
					this.items_cartel_notice = result.data.notice_mttrs_list
					this.items_cartel_bbs = result.data.post_list

					switch (this.item_cartel.cartl_concern_sphere_code){
						case 'CM00400001': this.$set(this.item_cartel, 'type', 'music'); break
						case 'CM00400002': this.$set(this.item_cartel, 'type', 'art') ; break
						case 'CM00400003': this.$set(this.item_cartel, 'type', 'fashion'); break
						case 'CM00400004': this.$set(this.item_cartel, 'type', 'influence'); break
					}

					this.item_search.page_number++

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getCartelCheer: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_cheer_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_cartel = result.data.post_list
					this.$storage.setQuery(this.item_search)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getCartelNotice: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_notice_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						//, board_number: this.$route.params.b_id
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_cartel = result.data.post_list
					this.$storage.setQuery(this.item_search)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getCartelBbs: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_bbs_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
						, srchtext: this.item_search.search_value
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_cartel_bbs = result.data.post_list
					this.$storage.setQuery(this.item_search)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_more = false
			}
		}
		,handleScroll: function(e){
			console.log(e)
		}
		,updateCartel: function(){
			if(process.env.VUE_APP_TYPE == 'sample'){
				let result = this.$sample.getSampleData('cartel')
				if(result.success){
					for(let item of result.data){
						if(item.bulletin_number == this.$route.params.idx){
							item.recomm++
						}
					}
				}

				localStorage.setItem('cartel', JSON.stringify(result.data))
			}
		}
		,getMyCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'get'
					, url: this.$api_url.api_path.get_my_cartel
					, data: {
						type: 'cartel_member'
						, key: 'cartel_id'
						, val: this.$route.params.idx
					}
				})
				if(result.success){
					this.item_my_cartel = result.data[0]
				}else{
					this.item_my_cartel = {}
					throw result.message
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onMore: function(item, index){
			this.is_more = true
			this.item_more = item
			this.item_more.index = index
		}
		, onDelete: function(){
			this.is_more = false
			this.is_delete = true
		}
		, handleClick(event, ref) {
			console.log(this.$refs, event, ref)
			this.$refs[ref][event]();
		}
		, getBoardConfig: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_config
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
					}
					, type: true
				})

				if (result.success) {
					this.item_board_config = result.data
				} else {
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, deleteBbs: async function(){
			this.is_more = false
			this.items_cartel_bbs.splice(this.item_more.index, 1)
		}
		, setMount: function(e){
			this.$emit('to', e)
			if(e.name == 'mafia049'){
				this.mount()
			}

			this.is_more = false
		}
		, mount: async function(){

			await this.getCartel()

			if(this.$route.params.code){
				await this.getBoardConfig()
			}

			if(this.$route.params.code == 'CA00700001'){
				await this.getCartelNotice()
			}else if(this.$route.params.code == 'CA00700001') {
				await this.getCartelCheer()
			}else if(this.$route.params.code){
				await this.getCartelBbs()
			}

		}

		, postEmoji: async function(item, emoji){
			try{
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_emoji
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: item.board_number
						, bulletin_number: item.bulletin_number
						, sympaty_icon_code: emoji.code
					}
					, type: true
				})

				if(result.success){
					item.is_empathy = emoji.code
					item.is_emoji = false
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_emoji = false
				this.$bus.$emit('on', false)
			}
		}

		, scrollListen: function(){
			if(this.mode == 'reply'){
				return false
			}else{

				let w = window.innerHeight
				let b = this.$refs.post_list_item_view.scrollHeight
				let t = document.body.scrollTop

				let max = b - w
				if(max <= 0){
					max = 0
				}

				if(t > max){
					this.getCartelBbs()
				}
			}
		}
		, removeScroll: function(){
			// console.log('removeScroll')
			document.body.removeEventListener('scroll', this.scrollListen)
		}
		, getScroll: function(){
			console.log('getScroll', this.$refs.post_list_item_view)
			this.h = this.$refs.post_list_item_view.scrollHeight
			document.body.addEventListener('scroll', this.scrollListen);
		}
		, toDetail: function(item){
			this.$emit('to', { name: 'mafia058', params: { idx: this.$route.params.idx, code: item.board_type_code ? item.board_type_code : this.$route.params.code, b_id: item.board_number, bbs_id: item.bulletin_number}})
		}
		, goBack: function(){
			this.$bus.$emit('to', { name: 'mafia049', params: { idx: this.$route.params.idx }})
		}
		, toJoin: function(){
			if(this.user.member_number){
				this.$emit('to', { name: 'mafia050', params: { idx: this.idx_cartel}} )
			}else{
				this.$bus.$emit('onLogin')
			}
		}
	}
	,async  created() {
		this.$bus.$emit('onLoad', this.program)
		if(this.item_search.search_value){
			await this.getCartelBbs()
		}
	}
}
</script>

<style>
.VueCarousel-dot-container { margin-top: 0px !important;}
.VueCarousel-dot-container button { margin-top: 0px !important; }
</style>