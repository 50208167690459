<template>
	<div>
		<div class="header">
			<div class="top top_mafi f-left">
				<div class="util">
					<button
						@click="$emit('cancel')"
						class="arrow_white"
					>
						<i class="icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit">{{ $language.common.sympathy }}</h2>
			</div>
		</div>
		<!-- //고정해더 -->
		<!-- 컨텐츠 내용 -->
		<div class="section_wrap pt-50" style="height: 100rem; overflow: auto; padding-bottom: 150px">
			<!-- 카르텔 리스트 -->
			<div class="thumbnail_wrap">
				<div class="container">
					<div class="row">
						<ul class="thumbnail_list emotion_list">
							<h3 class="hide">{{ $language.common.sympathy_members }}</h3>
							<li
								v-for="(item, index) in list_emoji"
								:key="'item_' + index"
							>
								<div class="circle_thumbnail_item">
									<a class="clear">
										<div class="picture">
											<img
												v-if="item.member_profle_nft_card_img_url"
												:src="$request.upload_url(item.member_profle_nft_card_img_url)" :alt="$language.cartel.recommend_cartel"
											>
											<img
												v-else
												:src="require('@/assets/image/@noimage.png')" :alt="$language.cartel.recommend_cartel"
											>
										</div>
										<div class="etc">
											<span v-if="false" class="faceicon_30 " :class="'f_' + item.type + '_30'">{{ $language.emoticon.best }}</span>
										</div>
										<div class="text_area">
											<strong>{{  item.nickname }}</strong>
										</div>
									</a>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!-- //카르텔 리스트 -->
		</div>
	</div>
</template>

<script>
	export default {
		name: 'mafia059'
		, props: ['bbs', 'user']
		, data: function(){
			return {
				program: {
					name: this.$language.common.sympathy
					, title: this.$language.common.sympathy
					, not_header: true
					, not_footer: true
					, type: 'carte_sub'
				}
				, items_emoji: []
				, icons_emoji: [
					{ code: 'CA01100001', type: 'best', txt: this.$language.emoticon.best}
					, { code: 'CA01100002', type: 'funny', txt: this.$language.emoticon.funny}
					, { code: 'CA01100003', type: 'like', txt: this.$language.emoticon.like}
					, { code: 'CA01100004', type: 'sad', txt: this.$language.emoticon.sad}
					, { code: 'CA01100005', type: 'surprise', txt: this.$language.emoticon.surprise}
					, { code: 'CA01100006', type: 'angry', txt: this.$language.emoticon.angry}
				]
			}
		}

		, computed: {
			list_emoji: function(){
				let self = this
				return this.items_emoji.filter(function(item){
					for(let t of self.icons_emoji){
						if(item.sympaty_icon_code == t.code){
							item.type = t.type
						}
					}

					return item
				})
			}
		}
		, methods: {

			getEmojiList: async function(){
				try{
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_emoji_list
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.bbs.cartl_number
							, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
							, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
						}
						, type: true
					})

					if(result.success){
						this.items_emoji = result.data.sympaty_list
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			this.getEmojiList();
		}
	}
</script>