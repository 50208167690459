<template>
	<div ref="body_comment" :class="{'bg-tv' : bbs_info.board_type_code == 'CA00700007'}">
		<div
			v-if="is_header"
			class="header"
			:class="{ 'bg-tv-line' : bbs_info.board_type_code == 'CA00700007'}"
		>
			<div class="cartel_top">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="goBack"
							class="arrow_white"
						>
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area">
						<h2 class="page_tit">{{  program.title }}</h2>
						<span class="page_subtit">{{  item_cartel.cartl_name }} &gt; {{ item_board_config.board_name }}</span>
					</div>
				</div>
			</div>
		</div>

		<div class="post_comment_main_view" style="padding: 3rem 0; padding-bottom: 80px" ref="post_comment_main_view">
			<span
				class="comment_count"
				:class="{ 'mt-50' : type != 'voting'}"
				style="padding: 0 2rem"
			>{{  $language.common.comment }} <em class="count">{{  items_comment.length }}</em></span>

			<!-- 댓글 -->
			<template
				v-if="items_comment.length > 0"
			>
			<template
				v-for="(comment, index) in list_comment"
			>
				<template
					v-if="comment.comment_level == 0"
				>
					<div
						v-show="mode == 'comment' || (mode == 'reply' && (comment.comment_number == item_comment.upper_comment_number))"
						:key="'comment_' + index"
						class="comment_layout"
					>
						<div
							v-if="comment.comment_delete_fg == 'Y'"
							class="comment_view" style="padding: 1rem 2rem;"
						>
							<div
								class="item_wrap"
							>
								<div class="write_user">
									<a class="u_profile">
										<img
											v-if="comment.comment_member_profle_nft_card_img_url"
											:src="$request.upload_url(comment.comment_member_profle_nft_card_img_url)"
										/>
										<img
											v-else
											:src="require('@/assets/image/@profile_inner.png')" alt="No Image"
										>
									</a>
									<div class="write_desc">
										<strong class="name">{{ $language.common.text_delete_comment }}</strong>
									</div>
								</div>
								<div
									class="comment_body"
								>
									<p></p>
								</div>
							</div>
						</div>
						<div
							v-else
							class="comment_view" style="padding: 1rem 2rem;"
							:class="{ on: mode == 'reply' && comment.comment_number == item_comment.upper_comment_number && item_comment.reply_id == ''}"
						>
							<div
								class="item_wrap"
								v-long-press="long_press_time"
								@long-press-start="longPress(comment, index)"
								@long-press-stop="longPressStop(comment, index)"
							>
								<div class="write_user">
									<a
										class="u_profile"
									>
										<img
											v-if="comment.comment_member_profle_nft_card_img_url"
											:src="$request.upload_url(comment.comment_member_profle_nft_card_img_url)" alt="No Image"
											style="width: 100%"
										>
										<img
											v-else
											:src="require('@/assets/image/@profile_inner.png')" alt="No Image"
										>
									</a>
									<div class="write_desc">
										<strong class="name"><em v-if="comment.is_block" class="state-block">{{  $language.common.cut_off }}</em> {{  comment.comment_member_nickname }}</strong>
									</div>
								</div>
								<div
									class="comment_body"
								>
									<p :inner-html.prop="comment.comment | nl2br" style="min-height: 40px; word-break: break-all; "></p>
									<img
										v-if="comment.comment_img_url"
										:src="$request.upload_url(comment.comment_img_url)"
										style="width: 100%; "
										class="mt-10"
									/>
								</div>
								<div
									class="write_info"
								>
									<span class="time">{{  getTimeStory('comment', comment.registdate) }}</span>
									<button
										v-if="mode != 'reply'"
										class="reply"
										@click="setReply(index, comment)"
									>{{  $language.common.write_reply }}</button>
								</div>
							</div>
						</div>
						<!-- 재댓글 -->

						<!-- //재댓글 -->
					</div>

				</template>
				<template
					v-else
				>
					<div
						:key="'reply_' + index"
						class="replylist_view" style="padding: 0"
					>
						<div
							v-if="mode == 'comment' || (mode == 'reply' && comment.upper_comment_number == item_comment.upper_comment_number)"
							class="item_wrap" style="padding: 1rem 1rem 1rem 2.4rem; margin: 0"
							:class="{ on: comment.comment_number == item_comment.reply_id}"
							v-long-press="long_press_time"
							@long-press-start="longPress(comment, index)"
							@long-press-stop="longPressStop(comment, index)"
						>
							<div style="margin-left: 3.2rem">
								<div style="display: flex">
									<a
										class="u_profile" style="margin-right: 10px; flex-basis: 3.2rem;"
									>
										<img
											v-if="comment.comment_member_profle_nft_card_img_url"
											:src="$request.upload_url(comment.comment_member_profle_nft_card_img_url)" alt="No Image"
											style="width: 100%"
											class="mt-10"
										>
										<img
											v-else
											:src="require('@/assets/image/@profile_inner.png')" alt="No Image"
										>
									</a>
									<div style="flex: 1">

										<div class="write_desc" style="height: 3.2rem; line-height: 3.2rem">
											<p v-if="comment.comment_delete_fg == 'Y'" style="font-size: 16px">{{ $language.common.text_delete_comment}}</p>
											<strong
												v-else
												class="name" style="font-size: 16px"
											><em v-if="comment.is_block" class="state-block">{{ $language.common.cut_off }}</em> {{ comment.comment_member_nickname }}</strong>

										</div>
										<div
											v-if="comment.comment_delete_fg != 'Y'"
											class="comment_body" style="position: relative"
										>
											<button
												v-if="comment.comment_number != comment.upper_comment_number"
												class="hashtag" style="position: absolute; left: 0"
											>&#64;{{ (comment.upper_comment_member_nickname ? comment.upper_comment_member_nickname : comment.comment_member_nickname) }}</button>

											<p :inner-html.prop="'@' + (comment.upper_comment_member_nickname ? comment.upper_comment_member_nickname : comment.comment_member_nickname) + ' ' + comment.comment | nl2br" style="min-height: 40px"><template v-if="comment.comment_number != comment.upper_comment_number"></template> </p>

											<img
												v-if="comment.comment_img_url"
												:src="$request.upload_url(comment.comment_img_url)"
												style="width: 100%"
											/>

											<div class="write_info">
												<span class="time">{{  getTimeStory('reply', comment.registdate) }}</span>
												<button
													v-if="mode != 'reply'"
													class="reply"
													@click="setReply(index, comment)"
												>{{  $language.common.write_reply }}</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>

			<!-- 댓글 -->
			</template>
			</template>

			<div
				v-else
				class="list_none"
			>
				<img :src="require('@/assets/image/list_none.png')" alt="Not Found History">
				<span>{{  $language.common.no_registered_comment }}</span>
			</div>
		</div>

		<div class="comment_write">
			<div
				v-if="item_comment.img_src"
				class="photo_area"
			>
				<div class="photo_area_view">
					<img
						v-if="item_comment.img_src"
						:src="$request.upload_url(item_comment.img_src)"
					/>
				</div>
				<button
					@click="item_comment.img_src = ''"
					class="view_close">
					<i class="icon-close"></i>
				</button>
			</div>

			<div
				v-if="is_join"
				class="size-px-14 pa-10 box-shadow justify-space-between color-blue-mafia"
				@click="toCartel"
			>
				<div class="ml-10">
					<span class="color-blue font-weight-700">카르텔에 가입</span>하고 댓글을 남겨보세요!
				</div>
				<button
				><v-icon class="color-gray">mdi-chevron-right</v-icon></button>
			</div>
			<div
				v-else-if="is_subscribe"
				class="write_area"
			>
				<div class="container">
					<h3 class="hide">{{  $language.common.comment_require }} <!-- 댓글을 남겨주세요 --></h3>
					<div class="write_main">
						<div class="btn_upload">
							<label class="comment_albumfile" aria-label="Upload Image"><input_file @change="setFile" accept="image/*" /></label>
						</div>
						<div class="mentions" >
							<textarea
								v-model="item_comment.content"
								:placeholder="$language.common.comment_require"

								@input="reSize"
								@keyup="checkKeyup"
								@keydown="checkKeydown"
								:style="item_comment.content == '' ? 'height: 20px': ''"
							></textarea>
						</div>
						<div class="submit_wrap">
							<button
								@click="postComment()"
								type="submit" class="write_submit"
							></button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<mafia062
			v-if="is_062"

			:user="user"
			:cartel="cartel"
			:bbs="bbs"
			:item_comment="item_target"

			@cancel="is_062 = false"
			@click="getBbsComment"
			@modify="onModify"
			@delete="deleteComment"
			style="z-index: 1003"
		>
		</mafia062>

		<mafia0632
			v-if="is_0632"

			:user="user"
			:cartel="item_cartel"
			:bbs="bbs"
			:comment="item_target"

			@cancel="is_0632 = false"
			@click="update"
			class="layer-popup"
		></mafia0632>

	</div>
</template>

<script>
	import mafia062 from '@/view/Cartel/mafia062'
	import mafia0632 from '@/view/Cartel/mafia063-2'
	import input_file from '@/components/InputFile'

export default {
	name: 'comment_list'
	, props: ['cartel', 'bbs', 'user', 'type']
	, components: {mafia062, input_file, mafia0632}
	, data: function(){
		return {
			program: {
				name: this.$language.confirm.write_comment.title
				, title: this.$language.confirm.write_comment.title
				, type: 'cartel_sub'
				, not_footer: true
				, not_header: true
			}
			, item_comment: {
				cartel_id: this.$route.params.idx ? this.$route.params.idx : this.bbs_info.cartl_number
				, bbs_code: this.$route.params.code
				, bbs_id: this.$route.params.bbs_id
				, user_id: this.user.id
				, user_nick: this.user.nick
				, user_img_src: this.user.img_src
				, upper_comment_number: ''
				, upper_comment_member_nickname: ''
				, content: ''
				, comment_img_url: ''
				, comment_level: 0
				, comment_member_profle_nft_card_img_url: ''

			}
			, items_comment: []
			, rows: 2
			, mode: 'comment'
			, item_cartel: {}
			, is_062: false
			, item_target: {}
			, scrollTop: 0
			, item_search: {
				page_number: 1
				, pagerecnum: this.$language.base.pagerecnum
			}
			, is_scroll: true
			, item_board_config: {}
			, upload_img: ''
			, h: 0
			, upper_index: 0
			, s_top: 0
			, is_0632: false
			, long_press_time: 1000
			, item_update: {
				sympaty_icon_code: ''
			}
			, is_shift: false
			, add_count: 0
			, bbs_info: this.bbs ? this.bbs : ''
		}
	}
	, computed: {
		list_comment: function(){
			return this.items_comment.filter(function(item){
				if(item.blok_member_fg == 'Y'){
					item.is_block = true
				}
				return item
			})
		}
		, is_subscribe: function(){
			let t = false
			if(this.item_board_config.board_type_code == 'CA00700006'){
				if(this.item_cartel.cartl_entry_state_code == 'CA00300004') {
					t = true
				}
			}else if(this.item_board_config.board_type_code != 'CA00700005' || this.bbs_info.member_post_subscrp_fg == 'Y'){
				t = true
			}
			return t
		}
		, is_join: function(){
			let t = false
			if(this.item_cartel.cartl_entry_state_code == ''){
				t = true
			}
			return t
		}
		, is_header: function(){
			let t = true
			if(this.type == 'voting'){
				t = false
			}
			return t
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				// this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.bbs_info.cartl_number
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
					this.items_cartel_notice = result.data.notice_mttrs_list
					this.items_cartel_bbs = result.data.post_list

					switch (this.item_cartel.cartl_concern_sphere_code){
						case 'CM00400001': this.$set(this.item_cartel, 'type', 'music'); break
						case 'CM00400002': this.$set(this.item_cartel, 'type', 'art') ; break
						case 'CM00400003': this.$set(this.item_cartel, 'type', 'fashion'); break
						case 'CM00400004': this.$set(this.item_cartel, 'type', 'influence'); break
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getBoardConfig: async function(){
			try{
				// this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_config
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.bbs_info.cartl_number
						, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs_info.board_number
					}
					, type: true
				})

				if(result.success){
					this.item_board_config = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getBbs: async function(){
			try{
				// this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_bbs
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.bbs_info.cartl_number
						, board_number: this.$route.params.b_id
						, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs_info.bulletin_number
					}
					, type: true
				})

				if(result.success){
					this.item_bbs = result.data

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getBbsComment: async function(){

			if(!this.is_scroll){
				return false
			}
			try{
				this.is_scroll = false
				// this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_bbs_comment_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.bbs_info.cartl_number
						, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs_info.board_number
						, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs_info.bulletin_number
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					if(this.items_comment.length > 0){
						console.log('concat')
						this.items_comment = this.items_comment.concat(result.data.comment_list)
					}else{
						console.log('!!concat')
						this.items_comment = result.data.comment_list
					}

					this.item_search.page_number++

					if(result.data.comment_list.length > 0){
						this.getScroll()
					}else{
						this.removeScroll()
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, postFile: async function(){
			try{
				// this.$bus.$emit('on', true)
				if(!this.upload_img){
					return true
				}
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_file
					, data: {
						member_number: this.user.member_number
						, file_upload_code: 'CM00700015'
						, appendix_file_div_code: 'CA01000001'
						, appendix_file_name: this.upload_img
					}
					, multipart: true
					, type: true
				})

				if(result.success){
					this.item_comment.comment_img_url = result.data.file_url
					return true
				}else{
					throw result.message
				}

			}catch (e) {
				console.log('postFile error', e)
				this.$bus.$emit('notify', { type: 'error', message: e})
				return false
			}finally {
				this.$bus.$emit('on', false)
				this.upload_img = ''
			}
		}

		, postComment: async function(){
			try{
				if(!this.item_comment.content || this.item_comment.content == ''){
					throw this.$language.common.comment_require_02
				}
				// this.$bus.$emit('on', true)

				if(await this.postFile()){
					let url = this.$api_url.api_path.post_comment
					let data = {

					}
					if(this.type == 'voting'){
						url = this.$api_url.api_path.post_voting_comment
						data = {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.bbs_info.cartl_number
							, cartl_vote_board_number: this.$route.params.v_id
							, cartl_vote_number: this.$route.params.e_id
							, upper_comment_number: this.item_comment.upper_comment_number
							, comment: this.item_comment.content
							, comment_img_url: this.item_comment.comment_img_url
						}
					}else{
						data = {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.bbs_info.cartl_number
							, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs_info.board_number
							, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs_info.bulletin_number
							, upper_comment_number: this.item_comment.upper_comment_number
							, upper_commnet_number_nickname: this.item_comment.upper_comment_member_nickname
							, comment: this.item_comment.content
							, comment_img_url: this.item_comment.comment_img_url
						}
					}
					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: data
						, type: true
					})

					if(result.success){
						this.item_comment.comment_number = result.data.comment_number
						this.item_comment.registdate = this.$date.getNow('-')
						this.item_comment.comment = this.item_comment.content
						this.item_comment.comment_member_profle_nft_card_img_url = this.user.profle_nft_card_img_url
						this.item_comment.comment_level = this.item_comment.upper_comment_number ? 1 : 0
						this.item_comment.comment_member_nickname = this.user.nickname
						this.item_comment.comment_member_number = this.user.member_number

						await this.appendComment(this.item_comment)
						this.item_comment.content = ''
						if(this.type == 'voting'){

							this.item_comment = {
								cartel_id: this.$route.params.idx ? this.$route.params.idx : this.bbs_info.cartl_number
								, bbs_id: this.$route.params.e_id
								, user_id: this.user.id
								, user_nick: this.user.nick
								, user_img_src: this.user.img_src
								, upper_comment_number: ''
								, upper_comment_member_nickname: ''
								, content: ''
								, comment_img_url: ''
							}
						}else{

							this.item_comment = {
								cartel_id: this.$route.params.idx ? this.$route.params.idx : this.bbs_info.cartl_number
								, bbs_code: this.$route.params.code ? this.$route.params.code : this.bbs_info.board_type_code
								, bbs_id: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs_info.board_number
								, user_id: this.user.id
								, user_nick: this.user.nick
								, user_img_src: this.user.img_src
								, upper_comment_number: ''
								, upper_comment_member_nickname: ''
								, content: ''
								, comment_img_url: ''
							}
						}

						this.mode = 'comment'
						setTimeout(() => {
							this.$refs.body_comment.scrollTop = this.$refs.body_comment.scrollHeight
						}, 200)

						this.$emit('click')
					}else{
						throw result.message
					}
				}

			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getTimeStory: function(type, created_at){
			return this.$date.getTimeStory(created_at)
		}
		, setFile: function(e){

			this.upload_img = e[0]
			console.log('this.upload_img', this.upload_img)

			const reader = new FileReader()
			let self = this
			reader.onload = function(e){
				// console.log('onload', e)
				self.$set(self.item_comment, 'img_src', e.target.result)
			}
			reader.readAsDataURL(e[0])

		}

		, reSize: function(e){
			e.target.style.height = 'auto'

			e.target.style.height = e.target.scrollHeight + 'px'

			console.log(e.target.innerHeight)
			if(Number(e.target.style.height.replace('px', '')) >= 120) {
				e.target.style.height = 120 + 'px'
			}else{
				if(e.target.style.height == '40px'){
					if(e.target.value.search(/\n/g) == -1){
						e.target.style.height = '20px'
					}
				}
			}
		}
		, setReply: function(index, comment){

			this.s_top = this.$refs.body_comment.scrollTop
			this.upper_index = index
			if(comment.comment_level == 0){
				this.item_comment.upper_comment_number = comment.comment_number
			}else{
				this.item_comment.upper_comment_number = comment.upper_comment_number
			}

			this.item_comment.upper_comment_member_nickname = comment.comment_member_nickname
			this.$set(this.item_comment, 'reply_id', comment.comment_level == 0 ? '' : comment.comment_number)
			this.mode = 'reply'
			this.program.title = this.$language.common.write_reply

			console.log(`comment.upper_comment_number :${comment.upper_comment_number}, comment.comment_number: ${comment.comment_number}, this.item_comment.reply_id: ${this.item_comment.reply_id}`)

		}
		, goBack: function(){

			if(this.mode == 'comment'){
				if(this.$route.params.type == 'detail'){
					this.$bus.$emit('to', {
						name: this.$route.name,
						params: {
							idx: this.$route.params.idx,
							code: this.$route.params.code,
							b_id: this.$route.params.b_id,
							bbs_id: this.$route.params.bbs_id,
							type: null
						},
						hash: 'detail'
					})
				}else{
					this.$emit('cancel', this.item_update)
				}
			}else{
				this.mode = 'comment'
				this.item_comment = {}
				this.program.title = this.$language.confirm.write_comment.title
			}

		}
		, longPress: function(item){
// console.log('longPress', item)
			item.is_long_press = true
		}
		, longPressStop: function(item, index){
			if(item.is_long_press){
				this.is_062 = true
				this.item_target = item
				this.item_target.index = index
			}
			item.is_long_press = false
		}
		, scrollListen: function(){
			if(this.mode == 'reply'){
				return false
			}else{

				let w = window.innerHeight
				let b = this.$refs.post_comment_main_view.scrollHeight
				let t = this.$refs.body_comment.scrollTop

				let max = b - w
				if(max <= 0){
					max = 0
				}

				if(t > max){
					this.getBbsComment()
				}
			}
		}
		, removeScroll: function(){
			// console.log('removeScroll')
			this.$refs.body_comment?.removeEventListener('scroll', this.scrollListen)
		}
		, getScroll: function(){
			console.log('getScroll')
			this.h = this.$refs.post_comment_main_view.scrollHeight
			this.$refs.body_comment.addEventListener('scroll', this.scrollListen);
		}
		,appendComment: function(obj){

			if(obj.upper_comment_number){

				this.items_comment.splice(this.upper_index + 1, 0, obj)

				setTimeout(() => {
					this.$refs.body_comment.scrollTop = this.s_top
				}, 100)
			}else{
				this.items_comment.push(obj)
			}
		}
		, onModify: function(){
			this.is_062 = false
			this.is_0632 = true
		}
		, update: function(item){
			console.log('update', this.item_target, item)

			item.comment = item.content
			this.$set(this.items_comment, this.item_target.index, item)

			this.is_0632 = false
		}
		, deleteComment: function(){
			this.items_comment.splice(this.item_target.index, 1)
			this.is_062 = false
		}
		, checkKeyup: function (e) {
			console.log('checkKeyup', e)
			if (e.key == 'Control') {
				this.is_shift = false
			}
		}
		, checkKeydown: function (e) {
			console.log('checkKeydown', e)
			if (e.key == 'Control') {
				this.is_shift = true
			}

			if (e.key == 'Enter') {
				if (this.is_shift) {
					e.preventDefault()
					this.postComment()
				}
			}
		}
		, getShortsComment: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_shorts_comment
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx || this.bbs_info.cartl_number
						, board_number: this.$route.params.b_id || this.bbs_info.board_number
						, bulletin_number: this.bbs_info.bulletin_number
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					if(this.items_comment.length > 0){
						console.log('concat')
						this.items_comment = this.items_comment.concat(result.data.comment_list)
					}else{
						console.log('!!concat')
						this.items_comment = result.data.comment_list
					}

					this.item_search.page_number++

					if(result.data.comment_list.length > 0){
						this.getScroll()
					}else{
						this.removeScroll()
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toCartel: function(){
			this.$bus.$emit('to', { name: 'mafia049', params: { idx: this.$route.params.idx ? this.$route.params.idx : this.item_cartel.cartl_number }})
		}
		, getVotingComment: async function(){
			if(!this.is_scroll){
				return false
			}
			try{
				this.is_scroll = false
				// this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_voting_comment_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.bbs_info.cartl_number
						, cartl_vote_board_number: this.$route.params.v_id
						, cartl_vote_number: this.$route.params.e_id
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					if(this.items_comment.length > 0){
						console.log('concat')
						this.items_comment = this.items_comment.concat(result.data.comment_list)
					}else{
						console.log('!!concat')
						this.items_comment = result.data.comment_list
					}

					this.item_search.page_number++

					if(result.data.comment_list.length > 0){
						this.getScroll()
					}else{
						this.removeScroll()
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}

	,async created() {

		await this.getCartel()

		if(this.type == 'voting'){
			await this.getVotingComment()
		}else{
			await this.getBoardConfig()
			if(this.item_board_config.board_type_code == 'CA00700006'){
				await this.getShortsComment()
			}else{
				await this.getBbsComment()
			}
		}
	}
}
</script>