<template>
	<div
		v-if="items.length == 1"
	>
		<div
			v-for="(file, f_index) in items"
			:key="'item_' + index + '_file_' + f_index"
			class="text-center position-relative"
		>
			<template
				v-if="item.is_blur"
			>
				<div
					class="position-absolute-full flex-column justify-center items-center"
					style="z-index: 1"
					@click="$emit('toDetail', item, index)"
				>
					<img
						:src="require('@/assets/image/ico_lock.png')"
						class="width-10" style="max-width: 22px"
					>
					<div class="color-white mt-10 size-px-16 text-shadow">구독 서비스 가입 후 이용해주세요</div>
				</div>

				<img
					v-for="(part, p_index) in file.parts"
					:key="'part_' + p_index"
					:src="part.file_url"
					@error="$bus.$emit('onErrorCartelCharacterImage', $event)"
					class="object-cover"
					loading="lazy"
				/>
			</template>

			<template
				v-else
			>
				<template
					v-if="file.is_image"
				>
					<img
						v-for="(part, p_index) in file.parts"
						:key="'part_' + p_index"
						:src="part.file_url"
						@click="$emit('toDetail', item, index)"
						@error="$bus.$emit('onErrorImage', $event)"
						class="object-cover"
						loading="lazy"
					/>
				</template>

				<template
					v-if="file.is_video"
				>
					<template
						v-if="file.is_trans"
					>
						<div
							v-if="item.board_type_code == 'CA00700005'"
						>
							<video
								v-if="false && file.is_on"
								crossorigin="use-credentials"
								datatype=""
								:src="file.video.src"
								autoplay
								controls
							></video>
							<VideoJs
								v-if="file.is_on"
								:item="file"
								:id="file.video_id"
								class="full-height width-100"
								:controls="true"
								:custom="true"
								:pause="file.is_pause"
								:play="file.is_play"
								:is_video="false"
								:is_auto="true"
								:is_sample="file.is_sample"
							></VideoJs>
							<div
								v-else
								class="flex-column justify-center items-center pa-50 in-hover"
								style="min-height: 150px"
							>
								<button
									@click="getCookieHeader(file, item.bulletin_number, f_index, file.parts[0].file_url)"
								><v-icon class="color-white " large>mdi-play-circle-outline</v-icon></button>

							</div>
						</div>
						<div
							v-else
							class="bg-black wrap-div position-relative"
							:id="file.wrap_id"
							style="min-height: 150px;"
						>
							<div
								v-if="file.is_trans"
								@click="loadVideo(file, item.bulletin_number, f_index, file.parts[0].file_url)"
								class="position-absolute-full flex-column justify-center items-center pa-50 in-hover"
								style="min-height: 150px"
								:id="file.cover_id"
							>
								<template
									v-if="file.player"
								>
									<button
										v-if="file.player.paused()"
									><v-icon class="color-white " large>mdi-play-circle-outline</v-icon></button>
									<button
										v-else
									><v-icon class="color-white pause" large>mdi-pause-circle-outline</v-icon></button>
								</template>
								<button
									v-else
								><v-icon class="color-white " large>mdi-play-circle-outline</v-icon></button>
							</div>
							<div
								v-else
								class="full-height flex-column justify-center color-white size-px-14 video_none"
								style="min-height: 150px"
							>{{ file.d }}</div>
						</div>
					</template>
				</template>

				<div
					v-if="file.is_audio"
					class="text_view"
					style="position: static !important; padding: 0; width: 100%"
				>
					<figure class="audio_wrap">
						<div class="audio_bg">
							<span class="hide">
								{{ $language.common.soundBackgroundImg }}
							</span>
						</div>
						<div class="bg-gray">
							<video
								width="100%"
								controls
								height="40px"
							>
								<source

									v-for="(part, p_index) in file.parts"
									:key="'part_' + p_index"
									:src="part.file_url"
								>
							</video>
						</div>
					</figure>
				</div>
			</template>
		</div>
	</div>
	<carousel
		v-else
		:perPage="1"
	>
		<slide
			v-for="(file, f_index) in items"
			:key="'item_' + index + '_file_' + f_index"
			class="photo_collage_view flex-column justify-center items-center"
		>
			<template
				v-if="file.is_image"
			>
				<div
					v-if="item.is_blur"
				>
					<div
						class="position-absolute-full flex-column justify-center items-center"
						style="z-index: 1"
						@click="$emit('toDetail', item, index)"
					>
						<img
							:src="require('@/assets/image/ico_lock.png')"
							class="width-10" style="max-width: 22px"
						>
						<div class="color-white mt-10 size-px-16 text-shadow">구독 서비스 가입 후 이용해주세요</div>
					</div>

					<img
						v-for="(part, p_index) in file.parts"
						:key="'part_' + p_index"
						:src="part.file_url"
						@error="$bus.$emit('onErrorCartelCharacterImage', $event)"
						class="object-cover"
						loading="lazy"
					/>
				</div>

				<img
					v-else
					v-for="(part, p_index) in file.parts"
					:key="'part_' + p_index"
					:src="part.file_url"
					@click="$emit('toDetail', item, index)"
					@error="$bus.$emit('onErrorImage', $event)"
					class="width-100"
					loading="lazy"
				/>
			</template>

			<template
				v-else
			>
				<div
					v-if="item.is_blur"
					class="position-relative full-height"
				>
					<div
						class="position-absolute-full flex-column justify-center items-center"
						style="z-index: 999"
						@click="$emit('toDetail', item, index)"
					>
						<img
							:src="require('@/assets/image/ico_lock.png')"
							class="width-10" style="max-width: 22px"
						>
						<div class="color-white mt-10 size-px-16">구독 서비스 가입 후 이용해주세요</div>
					</div>

					<img
						:src="file.parts[0].file_url"
						@error="$bus.$emit('onErrorCartelCharacterImage', $event)"
						class="object-cover"
						loading="lazy"
					/>
				</div>

				<template
					v-else
				>
					<div
						v-if="file.is_video"
						class="bg-black wrap-div position-relative width-100"
						:id="file.wrap_id"
						style="min-height: 150px;"
					>
						<div
							@click="loadVideo(file, item.bulletin_number, f_index, file.parts[0].file_url)"
							class="position-absolute-full flex-column justify-center items-center pa-50 shadow"
							style="min-height: 150px"
							:id="file.cover_id"
						><img :src="require('@/assets/image/btn_play.png')" class="width-10" style="max-width: 22px"/></div>
					</div>
					<video
						v-if="false && file.is_video"
						width="100%"
						controls
					>
						<source
							v-for="(part, p_index) in file.parts"
							:key="'part_' + p_index"
							:src="part.file_url"
							type="video/webm"
						>
					</video>
					<div
						v-if="file.is_audio"
						class="text_view"
						style="position: static !important; padding: 0; width: 100%"
					>
						<figure class="audio_wrap">
							<div class="audio_bg">
							<span class="hide">
								{{ $language.common.soundBackgroundImg }}
							</span>
							</div>
							<div class="bg-gray">
								<video
									width="100%"
									controls
									height="40px"
								>
									<source
										v-for="(part, p_index) in file.parts"
										:key="'part_' + p_index"
										:src="part.file_url"
									>
								</video>
							</div>
						</figure>
					</div>
				</template>
			</template>
		</slide>
	</carousel>
</template>

<script>
	import { Carousel, Slide } from 'vue-carousel';
	import {encodeStorage} from "@/resources/storage/localStorage";
	import VideoJs from "@/components/VideoJs";
	export default {
		name: 'CartelBbsFile'
		, props: ['user', 'item', 'index', 'paused']
		, components: {VideoJs, Carousel, Slide}
		, data: function(){
			return {
				items: []
				, player: ''
				, videoOptions: {
				}
				, baseUrl: ''
			}
		}
		, methods: {

			getCookieHeader: async function(item, b, index, src){
				try {
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_board_file_cookie_header
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.item.cartl_number ? this.item.cartl_number : this.$route.params.idx
							, board_number: this.item.board_number ? this.item.board_number : this.$route.params.b_id ? this.$route.params.b_id : item.board_number
							, bulletin_number: item.bulletin_number
							, post_appendix_file_indexnumber: item.post_appendix_file_indexnumber
						}
						, type: true
						, credent: true
					})

					if(result.success){
						// await this.getRealFile(item, index)
						// this.loadVideo(item, b, index, src)
						setTimeout(() => {
							this.onTv(item, b, index, src)
						}, 100)

					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
				}
			}
			, getRealFile: async function(item, index, header){

				let domain = process.env.VUE_APP_DOMAIN
				let domain_m = process.env.VUE_APP_DOMAIN_M
				let domain_stg = process.env.VUE_APP_DOMAIN_STG
				let domain_stgm = process.env.VUE_APP_DOMAIN_STG_M
				let domain_dev = process.env.VUE_APP_DOMAIN_DEV
				let domain_devm = process.env.VUE_APP_DOMAIN_DEV_M
				let dev = process.env.VUE_APP_DEV
				let server = process.env.VUE_APP_SERVER
				let stg = process.env.VUE_APP_STG
				let local = process.env.VUE_APP_LOCAL
				let location = window.location.href

				let baseUrl = ''

				this.$set(item, 'is_image', item.post_appendix_file_div_code == 'CA01000001' ? true : false)
				this.$set(item, 'is_video', item.post_appendix_file_div_code == 'CA01000002' ? true : false)
				this.$set(item, 'is_audio', item.post_appendix_file_div_code == 'CA01000003' ? true : false)

				// 운영계 API 호출
				if(location.indexOf(domain) > -1 || location.indexOf(domain_m) > -1) {
					baseUrl = server

					// 검증계 API 호출
				}else if(location.indexOf(domain_stgm) > -1 || location.indexOf(domain_stg) > -1){
					baseUrl = stg

					// 개발계 API 호출
				}else if(location.indexOf(domain_dev) > -1 || location.indexOf(domain_devm) > -1){
					baseUrl = dev

					// 로컬 API 호출
				}else{
					baseUrl = local
				}

				let url = baseUrl + '/' + this.$api_url.api_path.get_board_file_stream
				if(!item.is_image){
					url = baseUrl + '/' + this.$api_url.api_path.get_board_file_stream
				}
				let default_header = {
					'Content-Type': 'application/json'
					, 'v': '1.0.0'
					, 'Content-Security-Policy' : 'upgrade-insecure-requests'
				}
				if(header){
					// default_header = header
				}

				let d = url.split('/::')
				if(d.length > 1){
					default_header.v = d[1]
					url = d[0]
				}

				let session_token = encodeStorage.getSessionToken()
				//console.log('session_token', session_token)

				if(session_token){
					default_header.a = session_token
					//console.log('default_header', default_header)
				}

				let size = 1;
				if(this.$route.params.bbs_id == item.bulletin_number){
					size = item.post_appendix_file_partial_list.length > 0 ? item.post_appendix_file_partial_list.length : 1
				}

				this.$set(this.items, index, {
					parts: []
					, is_image: item.is_image
					, is_video: item.is_video
					, is_audio: item.is_audio
				})

				for(let i = 0; i < size; i++){

					if(!this.user.member_number){
						this.$set(this.items[index].parts, i, {
							file_url: 'error'
						})
						continue
					}

					let data = {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : item.cartl_number
						, board_number: this.$route.params.b_id ? this.$route.params.b_id : item.board_number
						, bulletin_number: item.bulletin_number
						, appendix_file_div_code: item.appendix_file_div_code
						, appendix_file_url: item.post_appendix_file_partial_list.length > 0 ? item.post_appendix_file_partial_list[i].appendix_file_url + '/' + item.post_appendix_file_partial_list[i].appendix_file_name : item.post_appendix_file_org_url
						, img_pay_div_code: item.img_pay_div_code
					}

					data = new URLSearchParams(data).toString()

					await fetch(url + '?' + data, {
						headers: default_header
					})
					.then((response) => response.body)
					.then((body) => {
						const reader = body.getReader();

						return new ReadableStream({
							start(controller) {
								return pump();

								function pump() {
									return reader.read().then(({ done, value }) => {
										// When no more data needs to be consumed, close the stream
										if (done) {
											controller.close();
											return;
										}

										// Enqueue the next data chunk into our target stream
										controller.enqueue(value);
										return pump();
									});
								}
							},
						});
					})
					.then((stream) => new Response(stream))
					.then((response) => response.blob())
					.then((blob) => {

						let reader = new FileReader()
						reader.onload = (e) => {
							if(e.target.result){
								this.$set(this.items[index].parts, i, {
									file_url: e.target.result
								})
							}else{
								this.$set(this.items[index].parts, i, {
									file_url: 'fail'
								})
							}
						}

						reader.readAsDataURL(blob)
					})
					.catch(() => {
						this.$set(this.items[index].parts, i, {
							file_url: 'error'
						})
					});
				}
			}
			, loadVideoEvent: function(err){
				if(err.code === 404){
					setTimeout( () => {
						this.player.src(
							{
								src: this.item_video.src
								, withCredentials: true
							}
						);
						this.player.play();
					}, 5000)
				}
			}
			, destroyed(){
				this.items.filter( ( item) => {
					item.post_file_url.filter( ( file) => {
						if(file.player){

							file.player.dispose()
						}
					})
				})
			}
			, loadvideo: async function(file, b, index, src){
				try {
					const result = await this.$Request({
						method: 'get'
						, url: src
						, type: true
						, credent: true
					})

					if(result.success){
						// console.log('1')
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
				}
			}
			, loadVideo: function(file, b, index, src){

				if(file.is_load) {
					if(file.player.paused()){
						file.player.play()
					}else{
						file.player.pause()
					}

					return false
				}

				console.log('player initialize start')
				window.registerIVSTech(window.videojs)
				// IVS Player와 Video.js 통합 설정
				this.videoOptions = {
					techOrder: ["AmazonIVS"],
					autoplay: true,
					playsinline: true,
					restoreEl: true
					, html5: {
						hls: {
							withCredentials: true
							, overrideNative: true
						}
						,dash: {
							setXHRWithCredentialsForType: ['MPD', true],
						}
					}
					, hls: {
						withCredentials: true
						, overrideNative: true
					}
					, withCredentials: true
					, origin: this.baseUrl
				};

				let video = document.createElement('video')
				let video_id = file.video_id
				let wrap_id = file.wrap_id
				let cover_id = file.cover_id

				video.id = video_id
				video.onloadedmetadata = (e) =>{
					console.log('onloadedmetadata', e)
				}
				document.getElementById(wrap_id).prepend(video)

				let player = window.videojs(video_id, this.videoOptions, () => {
					console.log('Player is ready to use');
				});

				this.$set(file, 'player', player)

				file.player.src(src);
				console.log(11)

				/*
				player.ready(() =>{
					player.src({
						src: src
						, type: 'application/x-mpegURL'
						, withCredentials: true
					});
				});

				 */

				// file.player.play();

				// this.playerEvent = this.player.getIVSEvents().PlayerEventType;
				// this.player.getIVSPlayer().addEventListener(this.playerEvent.ERROR, this.loadVideoEvent, true);
				file.player.on('waiting', function () {
					console.log('Video is buffering...');
					// 버퍼링 상태에서 필요한 처리를 수행
				});

				file.player.on('canplay', function () {
					console.log('Video can start playing.');
					this.on_video = true
					// 버퍼링이 완료되고 재생이 시작 가능한 상태에서 필요한 처리를 수행
				});

				file.player.on('playing', () => {
					this.$set(file, 'is_load', true)
					console.log('Video is playing.');
					file.on_video = true
					// 비디오 재생이 시작될 때 필요한 처리를 수행
					let width = document.getElementById(wrap_id).clientWidth
					let height = document.getElementById(wrap_id).clientHeight
					console.log(width, height)
					document.getElementById(wrap_id).style.width = '100%'
					// document.getElementById('wrap-' + b).style.height = height + 'px'
					document.getElementById(cover_id).style.width = '100%'
					// document.getElementById('cover-' + b).style.height = height + 'px'
				});

				file.player.on('stalled', function () {
					console.log('Video playback stalled.');
					file.on_video = false
					// 재생이 중단되거나 지연될 때 필요한 처리를 수행
				});

				file.is_streaming = true

				// this.setSteamVideo(file, src)
			}
			, setSteamVideo: function(file, src){

				setTimeout( () => {
					if(file.player){
						file.player.src(
							{
								src: src
								, withCredentials: true
							}
						);
						file.player.play();
					}
				}, 300)
			}
			, onTv: function(item, b, index, src){
				this.$set(item, 'video', {
					src: src
				})
				this.$set(item, 'cover', {
					src: ''
				})
				this.$set(item, 'is_on', true)
				console.log('onTv', item, b, index, src)
			}
			, onLoadMetadata: function(e){
				console.log('onLoadMetadata', e)
			}
		}

		, async created() {
// console.log('in bbs file', this.item)
			let domain = process.env.VUE_APP_DOMAIN
			let domain_m = process.env.VUE_APP_DOMAIN_M
			let domain_stg = process.env.VUE_APP_DOMAIN_STG
			let domain_stgm = process.env.VUE_APP_DOMAIN_STG_M
			let domain_dev = process.env.VUE_APP_DOMAIN_DEV
			let domain_devm = process.env.VUE_APP_DOMAIN_DEV_M
			let dev = process.env.VUE_APP_DEV
			let server = process.env.VUE_APP_SERVER
			let stg = process.env.VUE_APP_STG
			let local = process.env.VUE_APP_LOCAL
			let location = window.location.href

// 운영계 API 호출
			if(location.indexOf(domain) > -1 || location.indexOf(domain_m) > -1) {
				this.baseUrl = server

// 검증계 API 호출
			}else if(location.indexOf(domain_stgm) > -1 || location.indexOf(domain_stg) > -1){
				this.baseUrl = stg

// 개발계 API 호출
			}else if(location.indexOf(domain_dev) > -1 || location.indexOf(domain_devm) > -1){
				this.baseUrl = dev

// 로컬 API 호출
			}else{
				this.baseUrl = local
			}

			if(this.item.post_file_list && this.item.post_file_list.length > 0) {

				for(let [i, file] of this.item.post_file_list.entries()){
					let r = (Math.random() + '').slice(-4)

					let is_trans = false
					let d = ''
					switch (file.post_appendix_file_transform_info?.transform_state_code){

						case 'CA04100007':
							is_trans = true
							break;
						default:
							d = '업로드 중이거나 재생할 수 없는 파일입니다'
							break;
					}
					// console.log(this.item.contents, is_trans)

					let t = (this.$route.params.idx ? this.$route.params.idx : '') + '_' +
						(this.$route.params.code ? this.$route.params.code : '') + '_' +
						(this.$route.params.b_id ? this.$route.params.b_id : '') + '_'  +
						(this.$route.params.bbs_id ? this.$route.params.bbs_id : '') + '_'  +
						this.item.bulletin_number + '_' + i + r

					if(this.item.board_type_code == 'CA00700005'){
						if(this.item.member_post_subscrp_fg == 'Y'){
							if(file.appendix_file_div_code == 'CA01000001') {
								await this.getRealFile(file, i)
							}else if(file.appendix_file_div_code == 'CA01000002'){
								this.items.push({
									parts: [
										{ file_url: file.post_appendix_file_org_url }
									]
									, is_image: file.post_appendix_file_div_code == 'CA01000001' ? true : false
									, is_video: file.post_appendix_file_div_code == 'CA01000002' ? true : false
									, is_audio: file.post_appendix_file_div_code == 'CA01000003' ? true : false
									, video_id: 'video_' + t
									, wrap_id: 'wrap_' + t
									, cover_id: 'cover_' + t
									, bulletin_number: this.item.bulletin_number
									, post_appendix_file_indexnumber: file.post_appendix_file_indexnumber
									, is_trans: is_trans
									, d: d
								})
							}
						}else{
							if(file.appendix_file_div_code == 'CA01000001'){
								await this.getRealFile(file, i)
							}else{
								this.items.push({
									parts: [
										{ file_url: file.post_appendix_file_org_url}
									]
									, is_image: file.post_appendix_file_div_code == 'CA01000001' ? true : false
									, is_video: file.post_appendix_file_div_code == 'CA01000002' ? true : false
									, is_audio: file.post_appendix_file_div_code == 'CA01000003' ? true : false
									, video_id: 'video_' + t
									, wrap_id: 'wrap_' + t
									, cover_id: 'cover_' + t
									, bulletin_number: this.item.bulletin_number
									, post_appendix_file_indexnumber: file.post_appendix_file_indexnumber
									, is_trans: is_trans
									, d: d
								})
							}
						}
					}else{
						this.items.push({
							parts: [
								{ file_url: file.post_appendix_file_org_url }
							]
							, is_image: file.post_appendix_file_div_code == 'CA01000001' ? true : false
							, is_video: file.post_appendix_file_div_code == 'CA01000002' ? true : false
							, is_audio: file.post_appendix_file_div_code == 'CA01000003' ? true : false
							, video_id: 'video_' + t
							, wrap_id: 'wrap_' + t
							, cover_id: 'cover_' + t
							, bulletin_number: this.item.bulletin_number
							, post_appendix_file_indexnumber: file.post_appendix_file_indexnumber
							, is_trans: is_trans
							, d: d
						})
					}
				}
			}
		}
		, watch: {
			paused: {
				handler: function(call){
					if(call){
						this.items.filter( (item) =>{
							if(item.player){
								item.player.pause()
							}
						})
					}
				}
			}
		}
	}
</script>

<style>

.wrap-div div{
	width: 100%; height: 100%;
}
.wrap-div video {
	width: 100%; height: 100%;
}

.in-hover .pause{
	opacity: 0;
	transition: opacity 1s 3s ease
}
</style>