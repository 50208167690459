<template>
	<!-- 투표영역 -->
	<div>
		<div
			v-if="type == 'vote'"
			class="post_poll_view"
		>
			<div class="item_vote">
				<div class="add_head">
					<p class="add_title">{{  item_vote.title }}</p>
					<time class="max_time">{{ text_vote_limit }}</time>
				</div>
				<div class="add_detail">
					<div class="poll_total">
						<span class="poll_info">{{  $language.common.attending_count_current }} {{  text_total_votes }}</span>
					</div>
					<ul class="poll_question_list">
						<li
							v-for="(vote, index) in votes_list"
							:key="'vote_' + index"
						>
							<div class="thumbnail">
								<img
									v-if="vote.vote_img_url"
									:src="$request.upload_url(vote.vote_img_url)" alt=""
								>
								<img
									v-else
									:src="require('@/assets/image/@noimage.png')" alt=""
								>
							</div>
							<div class="thumb_checkbox">
								<div class="bright_radio">
									<input
										v-if="is_vote_possible"
										v-model="votes"
										:type="item_vote.vote_type_code == 'CA01400002' ? 'checkbox' : 'radio'"
										:id="'vote_' + index"
										:value="vote.vote_item_number"
									>
									<span
										v-if="is_votes"
										style="font-size: 14px"
									>{{  vote.vote_item_name }}</span>
									<label
										v-else
										:for="'vote_' + index"
									>{{  vote.vote_item_name }}</label>
								</div>
								<div class="graphbar_area">
									<span class="vote_data">{{ vote.vote_count }}vote ({{ vote.per }})</span>
									<div class="graphbar">
										<div class="graphbar_bar bg_red" :style="'width: ' + vote.per " aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
									</div>
								</div>
							</div>
						</li>
					</ul>
					<div
						class="poll_btn t-center"
					>
						<button
							@click="postVote"
							class="btn_l btn_fill_blue"
							:disabled="!is_vote_possible"
						>{{ text_vote_btn }}</button>
					</div>
				</div>
			</div>
		</div>

		<div
			v-else
			class="article_write_form article_vote_wrap"
		>
			<!-- 제목입력 -->
			<div
				class="article_write_form_subject"
				style="display: flex"
			>
				<input
					v-model="item_vote.title"
					class="input_text" type="text" :placeholder="$language.common.input_vote_name"
					maxlength="30"
				>
				<v-icon
					@click="is_reset = true"
				>mdi-close</v-icon>
			</div>
			<!-- //제목입력 -->
			<!-- 투표리스트 -->
			<div class="article_get_element vote_itemlist">
				<strong class="hide">{{  $language.common.voting }}</strong>
				<ul class="vote_list">
					<li
						v-for="(item, index) in item_vote.items"
						:key="'item_' + index"
					>
						<div class="media_content_item vote_album">
							<label>
								<img
									v-if="item.vote_img_url"
									:src="$request.upload_url(item.vote_img_url)"
								/>
								<img
									v-else
									:src="require('@/assets/image/@noimage.png')" alt=""
								>

								<input_file accept="image/*" multiple @change="setFile($event, item)" />
							</label>
							<button
								v-if="item.vote_img_url"
								@click="item.vote_img_url = ''"
								class="item_close"
							>
								<i class="icon-close"></i>
							</button>
						</div>
						<div class="bright_input input_vote">
							<div class="input_utill">
								<input
									v-model="item.vote_item_name"
									type="text" :placeholder="(index + 1) + '. ' + $language.common.input_vote_item" maxlength="20"
								>
								<div class="input_utill_area">
									<div class="btn_del">
										<!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
										<button
											v-if="item_vote.items.length > 2"
											@click="item_vote.items.splice(index, 1)"
											class="active"
										><span class="hide">{{  $language.common.close }}</span></button>
									</div>
								</div>
							</div>
						</div>
					</li>
				</ul>
				<!-- 투표항목추가 -->
				<div class="add_votelist">
					<button
						@click="setVoteSize"
						class="btn_l btn_fill_blue"
						:disabled="item_vote.items.length >= vote_max"
					><i class="icon-add"></i> {{  $language.common.add_vote_item }}</button>
				</div>
				<!-- //투표항목추가 -->
			</div>
			<!-- //투표리스트 -->
			<!-- 투표옵션설정 -->
			<div class="article_get_element">
				<div class="vote_option ">
					<div class="option_cell">
						<dl
							@click="is_date_picker = !is_date_picker"
						>
							<dt>{{  $language.common.endDate }} <!-- 종료일 --></dt>
							<dd>
								{{ text_vote_date }} <i class="icon-arrow-down"></i>
							</dd>
						</dl>
						<dl
							@click="is_time_picker = !is_time_picker"
						>
							<dt>{{  $language.common.endTime }} <!-- 종료시간 --></dt>
							<dd>
								{{ text_vote_time }} <i class="icon-arrow-down"></i>
							</dd>
						</dl>
						<dl
							@click="item_vote.is_multiple = !item_vote.is_multiple"
						>
							<dt class="toggle_tit">{{  $language.common.multiple_choice }} <!-- 복수선택 --></dt>
							<dd>
								<p class="toggle-group">
									<!-- TODO : 수신 허용시 .on 클래스 추가 -->
									<span class="btn-toggle " :class="{ on: item_vote.is_multiple }"><em class="hide">{{  $language.common.multiple_choice }}</em></span>
								</p>
							</dd>
						</dl>
					</div>
				</div>
			</div>
			<!-- //투표옵션설정 -->
		</div>


		<popup_confirm
			v-if="is_time_picker"
			@cancel="checkTime"
			@click="setTime"
		>
			<template
				v-slot:title
			>{{  $language.common.setEndTime }}</template>
			<template
				v-slot:main-txt
			>
				<div
					style="display: flex"
				>
						<ScrollPicker
							:options="items_time[0]"
							v-model="time[0]"
						></ScrollPicker>
						<ScrollPicker
							:options="items_time[1]"
							v-model="time[1]"
						></ScrollPicker>
						<ScrollPicker
							:options="items_time[2]"
							v-model="time[2]"
						></ScrollPicker>
				</div>
			</template>
		</popup_confirm>

		<popup_confirm
			v-if="is_reset"
			@cancel="is_reset = false"
			@click="resetVote"
		>
			<template
				v-slot:title
			>{{  $language.common.cancel_vote_write }}</template>
			<template
				v-slot:main-txt
			>{{  $language.common.cancel_vote_write_question }}</template>
			<template
				v-slot:sub-txt
			>{{  $language.common.cancel_vote_write_confirm }}</template>
		</popup_confirm>

		<div
			v-if="is_date_picker"
			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 9999; display: flex; flex-direction: column; align-items: center; justify-content: center"
		>
			<div
				style="position: absolute; left: 0; top: 0; width: 100%; height: 100%; background-color: black; opacity: 0.2"
			></div>
			<div
				style="border: 1px solid #bbb; z-index: 1002"
			>

				<v-date-picker
					v-model="date_picker"
					no-title
					scrollable
					locale="ko-KR"
				></v-date-picker>

				<button
					@click="checkDate();"
					style="width: 100%; padding: 10px; background-color: #3a44c0; color: white; font-size: 14px"

				>{{  $language.common.setting }}</button>
			</div>
		</div>

	</div>
	<!-- //투표영역 -->
</template>

<script>
	import "vue-scroll-picker/dist/style.css"
	import { ScrollPicker } from "vue-scroll-picker"
	import popup_confirm from "@/view/Layout/PopupConfirm";
	import input_file from '@/components/InputFile'

	export default {
		name: 'mafia_vote'
		, props: ['cartel', 'bbs', 'vote', 'type', 'user']
		, components: {popup_confirm, ScrollPicker, input_file }
		, data: function(){
			return {
				item_vote: {
					title: ''
					, items: [
						{ img_src: '', title: ''}
						, { img_src: '', title: ''}
					]
					, date: this.$date.getNextDay(this.$date.getToday('-'), '-', 1)
					, time: ['am', '12', '00']
					, is_multiple: false
					, vote_compl_fg: 'N'
				}
				, vote_max: 10
				, is_date_picker: false
				, date_picker: this.$date.getNextDay(this.$date.getToday('-'), '-', 1)
				, is_time_picker: false
				, items_time: this.$date.getTimeItems()
				, time: ['am', '12', '00']
				, is_reset: false
				, votes: []
				, items_vote: []
				, item_votes: {
					cartel_id: this.cartel.id
					, bbs_id: this.bbs.id
					, user_id: this.user.id
					, user_nick: this.user.nick
					, user_img_src: this.user.img_src
					, votes: this.votes
				}
			}
		}
		, computed: {
			text_vote_btn: function(){
				let t = this.$language.common.voting
				if(this.bbs.vote_info.vote_compl_fg == 'Y'){
					t = this.$language.common.complete_vote
				}
				if(this.is_votes_limit){
					t = this.$language.common.terminate_vote
				}
				return t
			}
			, text_vote_time: function(){
				let t = this.$language.common.setEndTime_require

				if(this.item_vote.time.length > 0){
					if(this.item_vote.time[0] == 'am'){
						if(this.item_vote.time[1] == 12){
							t =  this.$language.common.afternoon + ' '
						}else{
							t = this.$language.common.morning + ' '
						}
					}else{
						if(this.item_vote.time[1] == 12){
							t = this.$language.common.morning + ' '
						}else{
							t =  this.$language.common.afternoon + ' '
						}
					}

					t += this.item_vote.time[1] + this.$language.common.hour + ' '

					t += this.item_vote.time[2] + this.$language.common.minute + ' '
				}

				return t;
			}
			, text_vote_date: function(){
				let t = this.$language.common.setEndDate_require
        console.log('text_vote_date', this.item_vote.date)
				if(this.item_vote.date && this.item_vote.date != ''){
					let d = this.item_vote.date.split('-')
					t = `${d[0]}.${d[1]}.${d[2]} `
				}
				return t
			}
			, text_vote_limit: function(){
				let t = ''

				t = this.text_vote_date + ' ' + this.text_vote_time

				return t
			}
			, is_votes: function(){
				let t = false

				if(this.bbs.vote_info.vote_compl_fg == 'Y'){
					t = true
				}

				return t
			}
			, text_total_votes: function(){
				let t = 0

				t = this.item_vote.vote_participation_people_count

				return t
			}
			, is_votes_limit: function(){
				let t = false

				let limit = new Date(this.bbs.vote_info.vote_end_daytime)
				let now = new Date()

				console.log(limit + ' : ' + now)
				if(limit.getTime() < now.getTime()){
					t = true
				}

				return t
			}
			, is_vote_possible: function(){
				let t = false
				if(!this.is_votes_limit && this.item_vote.vote_compl_fg != 'Y'){
					t = true
				}
				return t
			}
			, votes_list: function(){
				let self = this
				return this.item_vote.items.filter(function(item){
					if(self.text_total_votes == 0){
						item.per = '0%'
					}else{
						if(item.vote_count > 0){
							item.per = (item.vote_count / self.text_total_votes * 100).toFixed(2) + '%'
						} else{
							item.per = '0%'
						}
					}

					console.log('item.per', item.per)
					return item
				})
			}
		}
		, methods:{

			setFile: function(e, item){
				console.log(e)

				const reader = new FileReader()

				let self = this
				reader.onload = function(e){
					self.$set(item, 'vote_img_url', e.target.result)
				}
				this.postFile(item, e[0])
				reader.readAsDataURL(e[0])
			}
			, postFile: async function(item, file){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_file
						, data: {
							member_number: this.user.member_number
							, file_upload_code: 'CM00700004'
							, appendix_file_div_code: 'CA01000001'
							, appendix_file_name: file
						}
						, multipart: true
						, type: true
					})

					if(result.success){
						item.vote_img_url = result.data.file_url
					}else{
						throw result.message
					}

				}catch (e) {
					console.log('postFile error', e)
					this.$bus.$emit('notify', { type: 'error', message: e})
					return false
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, setVoteSize: function(){
				if(this.item_vote.items.length >= this.vote_max){
					this.$bus.$emit('notify', { type: 'error', message: this.$language.common.error_vote_limit})
				}else{
					this.item_vote.items.push({ img_src: '', title: ''})
				}
			}
			, checkDate: function(){
				// console.log('checkDate', this.$date.getToday('-') , this.date_picker)
				if(this.$date.getToday('-') > this.date_picker){
					this.$bus.$emit('notify', { type: 'error', message: this.$language.common.error_date_not_today})
				}else{
					this.$set(this.item_vote, 'date', '')
					this.item_vote.date = this.date_picker

					this.is_date_picker = false

					this.setTime()
				}
			}
			, checkTime: function(){
				let date = this.date_picker + ' ' + this.$date.transTime(this.time)
				if(!this.$date.compare(date)) {
					this.$bus.$emit('notify', { type: 'error', message: this.$language.common.error_date_not_now})
				}else{
					this.is_time_picker = false
				}
			}
			, setTime: function(){
				let date = this.date_picker + ' ' + this.$date.transTime(this.time)

				if(this.$date.compare(date)){
					this.item_vote.time = this.time
					this.is_time_picker = false
				}else{
					this.is_time_picker = true
					this.$bus.$emit('notify', { type: 'error', message: this.$language.common.error_date_not_now})
				}
			}
			, resetVote: function(){
				this.$emit('cancel')
			}
			, getVoteList: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'get'
						, url: this.$api_url.api_path.get_vote_list
						, data: {
							type: 'vote_list'
							, key: 'bbs_id'
							, val: this.bbs.id
						}
					})

					if(result.success){
						this.items_vote = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postVote: async function(){
				try{
					if(this.votes === '' || this.votes.length === 0){
						throw this.$language.common.choice_vote_require + this.votes
					}else{
						this.item_votes.votes = this.votes
						if(!Array.isArray(this.votes)){
							this.votes = [this.votes]
						}
					}
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_vote
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.cartel.cartl_number
							, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
							, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
							, post_vote_number: this.item_vote.post_vote_number
							, vote_item_list: this.votes
						}
						, type: true
					})

					if(result.success){
						this.$emit('click')
						this.mount()
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}

			, vote_time: function(vote_end_daytime){
				let t = []

				if(vote_end_daytime){

					let tt = vote_end_daytime.split('T')[1].replace('Z', '').split(':')

					if(Number(tt[0]) > 11){
						t.push('pm')
						let d = Number(tt[0]) - 12
						if(d == 0){
							d = 12
						}
						t.push(d)
					}else{
						t.push('am')
						t.push(tt[0])
					}
					t.push(tt[1])
				}

				return t
			}
			, mount: function(){
				this.item_vote.vote_participation_people_count = this.bbs.vote_participation_people_count
				this.item_vote.post_vote_number = this.bbs.vote_info.post_vote_number
				this.item_vote.vote_compl_fg = this.bbs.vote_info.vote_compl_fg
				this.item_vote.title = this.bbs.vote_info.vote_title
				this.item_vote.time = this.bbs.vote_info.vote_end_daytime
				this.item_vote.items = this.bbs.vote_list
				this.item_vote.date = this.bbs.vote_info.vote_end_daytime.split('T')[0]
				this.item_vote.time = this.vote_time(this.bbs.vote_info.vote_end_daytime)
				this.item_vote.vote_type_code = this.bbs.vote_info.vote_type_code
				this.time = this.vote_time(this.bbs.vote_info.vote_end_daytime)
				this.date_picker = this.bbs.vote_info.vote_end_daytime.split('T')[0]
			}
		}
		, created() {
			if(this.bbs.vote_fg == 'Y'){
				this.mount()
			}
		}
		, watch: {
			item_vote:{
				deep: true
				, handler: function(call){
					this.$emit('change', call)
				}
			}
		}
	}
</script>
<style>
.v-date-picker-table .v-btn.v-btn--active { color: black !important;}
.v-date-picker-header__value button { font-size: 18px !important;}

.vue-scroll-picker{position:relative; height:10em;overflow:hidden;  background-color: white}
.vue-scroll-picker-list{position:absolute;left:0;right:0;top:0;bottom:0}
.vue-scroll-picker-list-rotator{position:absolute;left:0;right:0; top:calc(50% - 0.8em); font-weight: 0.8em}
.vue-scroll-picker-list-rotator .-selected { font-weight: bold; font-size: 1.2em; line-height: 1.4em}

.vue-scroll-picker-item{text-align:center; margin: 8px 0; height: 30px; line-height: 1.5em}
.vue-scroll-picker-item.-placeholder{color:#aaa}
.vue-scroll-picker-layer{position:absolute;left:0;right:0;top:0;bottom:0;}


.vue-scroll-picker-layer .top,
.vue-scroll-picker-layer .middle,
.vue-scroll-picker-layer .bottom{position:absolute}
.vue-scroll-picker-layer .top{box-sizing:border-box; border-bottom: 2px solid var(--blue01);  background:linear-gradient(180deg, #fff 10%, rgba(255,255,255,0.7));top:0;left: 10%;right:0; height:calc(50% - 1.3em);cursor:pointer; width: 80%}
.vue-scroll-picker-layer .middle{top:calc(50% - 16em);left:0;right:0;bottom:calc(50% - 16em); width: 80%}
.vue-scroll-picker-layer .bottom{border-top: 2px solid var(--blue01); background:linear-gradient(0deg, #fff 10%, rgba(255,255,255,0.7));bottom:0;left: 10%;right:0;height:calc(50% - 1.3em);cursor:pointer; width: 80%}

.vue-scroll-picker-group.flex{display:flex}.vue-scroll-picker-group:after{clear:both;display:table;content:""}.vue-scroll-picker-group>.vue-scroll-picker-1{float:left;width:8.33333%}.vue-scroll-picker-group>.vue-scroll-picker-2{float:left;width:16.66667%}.vue-scroll-picker-group>.vue-scroll-picker-3{float:left;width:25%}.vue-scroll-picker-group>.vue-scroll-picker-4{float:left;width:33.33333%}.vue-scroll-picker-group>.vue-scroll-picker-5{float:left;width:41.66667%}.vue-scroll-picker-group>.vue-scroll-picker-6{float:left;width:50%}.vue-scroll-picker-group>.vue-scroll-picker-7{float:left;width:58.33333%}.vue-scroll-picker-group>.vue-scroll-picker-8{float:left;width:66.66667%}.vue-scroll-picker-group>.vue-scroll-picker-9{float:left;width:75%}.vue-scroll-picker-group>.vue-scroll-picker-10{float:left;width:83.33333%}.vue-scroll-picker-group>.vue-scroll-picker-11{float:left;width:91.66667%}.vue-scroll-picker-group>.vue-scroll-picker-12{float:left;width:100%}
/*# sourceMappingURL=style.css.map*/

.add_detail .poll_question_list li .thumbnail { width:64px; height: 64px; padding-top: 0; border-radius: 1.1rem;}
.add_detail .poll_question_list li .thumbnail img { width:64px; height: 64px; }
</style>