<template>
	<input
		type="file"
		class="hide"
		:accept="accept"
		:placeholder="placeholder"
		:capture="capture"
		:multiple="multiple"
		ref="file"
		@change="toChange"
		data-type="1"
		:disabled="!is_permission"
	/>
</template>

<script>
export default {
	name: 'InputFileNew'
	, props: ['accept', 'placeholder', 'capture', 'multiple', 'file_upload_code_info']
	, data: function(){
		return{
			is_permission: false
			, upload_img: {

			}
			, file_upload_code: this.file_upload_code_info ? this.file_upload_code_info : 'CM00700015'
		}
	}
	, computed: {
		appendix_file_div_code: function(){
			let t = ''
			if(this.accept.indexOf('image') > -1){
				t = 'CA01000001'
			}else if(this.accept.indexOf('video') > -1){
				t = 'CA01000002'
			}else if(this.accept.indexOf('audio') > -1) {
				t = 'CA01000003'
			}
			return t
		}
	}
	, methods: {
		postPermission: function(){
			if(this.is_permission){
				return false
			}
			try {
				this.$common.inAppWebviewCommunity('android', 'permission')

				window.click = () => {
					this.doFile()
				}
				window.deninePermission = () => {
					this.$bus.$emit('notify', { type: 'error', message: '파일 첨부에 필요한 권한이 거부되어 있습니다. 앱 설정에서 권한승인이 필요합니다.'})
					this.$bus.$emit('goBack')
				}
			}catch (e){
				console.log(e)
				this.doFile()
			}
		}

		, doFile: function(){
			this.is_permission = true
		}

		, toChange: function(){
			this.postFile()
		}

		, postFile: async function(){
			try{
				if(!this.upload_img){
					return true
				}
				this.$bus.$emit('on', true)
				let login_info = this.$encodeStorage.getSessionAt()

				let domain = process.env.VUE_APP_DOMAIN
				let domain_m = process.env.VUE_APP_DOMAIN_M
				let domain_stg = process.env.VUE_APP_DOMAIN_STG
				let domain_stgm = process.env.VUE_APP_DOMAIN_STG_M
				let domain_dev = process.env.VUE_APP_DOMAIN_DEV
				let domain_devm = process.env.VUE_APP_DOMAIN_DEV_M
				let dev = process.env.VUE_APP_DEV
				let server = process.env.VUE_APP_SERVER
				let stg = process.env.VUE_APP_STG
				let local = process.env.VUE_APP_LOCAL
				let location = window.location.href

				let baseUrl = ''

// 운영계 API 호출
				if(location.indexOf(domain) > -1 || location.indexOf(domain_m) > -1) {
					baseUrl = server

// 검증계 API 호출
				}else if(location.indexOf(domain_stgm) > -1 || location.indexOf(domain_stg) > -1){
					baseUrl = stg

// 개발계 API 호출
				}else if(location.indexOf(domain_dev) > -1 || location.indexOf(domain_devm) > -1){
					baseUrl = dev

// 로컬 API 호출
				}else{
					baseUrl = local
				}

				for(let i = 0; i < this.$refs.file.files.length; i++){

					let data = new FormData();

					let file = this.$refs.file.files[i]

					data.append('member_number', login_info.member_number)
					data.append('file_upload_code', this.file_upload_code)
					data.append('appendix_file_div_code', this.appendix_file_div_code)
					data.append('appendix_file_name',  file)

					const options = {
						method: 'POST'
						, body: data
						, headers: {
							'Accept': 'application/json',
							'A': this.$encodeStorage.getSessionToken(),
							'V': '1.0.0'
							, 'Content-Security-Policy' : 'upgrade-insecure-requests'
						}
					};

					let url = baseUrl + '/' + this.$api_url.api_path.post_file
					await fetch(url, options).then(response => response.json()).then( (result) => {
						console.log(result.d)
						console.log(result.resultinfo)
						if(result.resultinfo.result_div_cd == 'OK'){
							this.$emit('change', result.d.file_url)
						}else{
							throw result.resultinfo.result_msg
						}
					}).catch( (e) => {
						console.log(e)
						throw e
					});
				}

			}catch (e) {
				console.log('postFile error', e)
				this.$bus.$emit('notify', { type: 'error', message: e})
				return false
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, created() {

		console.log(this.accept)
		try {
			// step1 현재 권한 조회
			this.$common.inAppWebviewCommunity('android', 'getPermission')

		}catch (e){
			console.log(e)
			// 앱이 아닌 경우 종료
			this.is_permission = true
		}

		this.$bus.$on('confirmPermission', () => {
			this.is_permission = true
		})
	}
}
</script>