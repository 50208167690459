<template>
	<div>
		<div class="header">
			<div class="cartel_top">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="goBack"
							class="arrow_white">
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area">
						<h2 class="page_tit">{{ text_title }}</h2>
						<span class="page_subtit">{{  item_cartel.cartl_name }}</span>
					</div>
				</div>
			</div>
			<div class="save mt-10">
				<button
					@click="doPost"
					class="btn_save"
					:disabled="is_disabled"
				>{{  $language.common.complete }}</button>
			</div>
		</div>
		<!-- //고정해더 -->
		<!-- 글쓰기 영역 -->
		<div class="write_wrap pt-70 pb-50">
			<div class="container">
				<div
					v-if="!is_notice"
					class="article_write_form_subject"
				>
					<span>{{ text_board_name }}</span>
				</div>
				<div
					v-show="is_notice"
					class="article_write_form"
				>
					<!-- 댓글 작성 -->
					<div class="article_write_option_check">
						<span class="option_name">{{  $language.confirm.write_comment.title }}</span>
						<em class="option_desc">{{  $language.confirm.write_comment.choice_comment }}</em>
						<div class="option_check-toggle">
							<p class="toggle-group">
								<!-- TODO : 수신 허용시 .on 클래스 추가 -->
								<button class="btn-toggle" :class="{ on: item_bbs.is_comment}" @click="item_bbs.is_comment = !item_bbs.is_comment"></button>
							</p>
						</div>
					</div>
					<!-- //댓글 작성 -->
					<!-- 제목입력 -->
					<div class="article_write_form_subject">
						<input
							v-model="item_bbs.title"
							:placeholder="$language.common.title_require"
							class="input_text"
							maxlength="50"
						/>
					</div>
					<!-- //제목입력 -->
				</div>

				<!-- 미디어 영역 -->
				<div class="article_media_list_wrap">
					<div class="media_wrap">

						<draggable
							v-model="upload_files"
							handle=".handle"
						>
							<div
								v-for="(file, index) in upload_files"
								:key="'files_' + index"
								class="photo_upload_item"
							>
								<div
									v-if="file.post_appendix_file_div_code == 'CA01000001'"
									class="media_content_item"
								>
									<img
										:src="file.src"
										style="width: 100%"
										@error="$bus.$emit('onErrorImage', $event)"
									/>
									<button class="item_close">
										<i
											@click="onRemove(file, index)"
											class="icon-close"
										></i>
									</button>
								</div>
								<div
									v-else-if="file.post_appendix_file_div_code == 'CA01000002'"
									class="media_content_item m_video"
								>
									<video
										width="100%"
										:ref="'video_' + index"
										@loadedmetadata="onLoadMetadata($event, index)"
									>
										<source
											:src="file.src"
											type="video/webm"
										>
									</video>
									<button class="item_close">
										<i
											@click="onRemove(index)"
											class="icon-close"
										></i>
									</button>
								</div>
								<div
									v-else-if="file.post_appendix_file_div_code == 'CA01000003'"
									class="media_content_item m_music"
								>
									<audio
										:src="file.src"
									></audio>
									<button class="item_close">
										<i
											@click="onRemove(index)"
											class="icon-close"
										></i>
									</button>
								</div>
								<div class="media_content_name">
									<p>{{  file.name }}</p>
								</div>
								<div class="media_content_drag handle">
									<div class="drag_bar">
										<img :src="require('@/assets/image/btn_order.png')" alt="">
									</div>
								</div>
							</div>
						</draggable>
					</div>
				</div>
				<!-- //미디어 영역 -->

				<div class="one_editor">
					<div class="ct_text_editor">
						<!-- 글쓰기영역 -->
						<mafia_vote
							v-if="false"
							:vote="item_bbs.vote_info"
							:cartel="item_cartel"
							:bbs="item_bbs"
							:user="user"

							@cancel="removeVote"
							@change="setVote"
						></mafia_vote>

						<div class="ct_container">
							<div class="ct_textarea">
								<!-- TODO : 내용입력시 placeholde 및 .ct_empty 클래스 제거 -->
								<textarea
									v-model="item_bbs.content"
									:placeholder="$language.common.content_require"
									ref="content"

									@input="reSize"

									style="min-height: 200px"
								></textarea>
							</div>
						</div>

						<!-- //글쓰기영역 -->
						<div
							v-if="!is_cheer"
							class="ct_button_area"
							style="z-index: 99"
						>
							<div class="container">
								<div class="toolbar_list_area">
									<ul class="toolbar_list">

										<li>
											<button><label><span class="toolbar_icon tb_photo">{{  $language.common.picture }}</span><input-file-new accept="image/*" multiple @change="setFile('image', $event)" /></label></button>
										</li>
										<li>
											<button
												@click="toFile"><label><span class="toolbar_icon tb_viedo" >{{  $language.common.video }}</span><input type="file" accept="video/*" multiple @change="setVideoFile('video', $event)" ref="file_video" class="hide" /></label></button>
										</li>
										<li>
											<button><label><span class="toolbar_icon tb_sound" >{{  $language.common.sound }}</span><input-file-new  accept="audio/*" multiple @change="setFile('audio', $event)" ref="file_sound"/></label></button>
										</li>
										<li>
											<button
												@click="resetVote"
											><span class="toolbar_icon tb_vote">{{  $language.common.voting }}</span></button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="is_subscribe"
				class="size-px-16 bg-gray-light pt-10 pb-50"
			>
				<div
					class="pa-20 bg-white"
				>
					<div>{{ $language.subscribe.title_subscribe_plan_choice}} <!-- 적용 플랜 선택 --></div>
					<div class="mt-10 size-px-13 color-gray">{{ $language.subscribe.title_subscribe_plan_choice}} <!-- 선택된 플랜 등급이상 구독 멤버에 한해 이글을 볼 수 있습니다. --></div>

					<div class="mt-10">
						<ul>
							<li
								v-for="(payment, p_index) in items_payment"
								:key="'payment_' + p_index"

								@click="setPlan(payment)"
								class="mt-10"
							>
								<v-icon
									v-if="payment.is_check"
									class="color-blue"
								>mdi-checkbox-marked-circle</v-icon>
								<v-icon
									v-else
								>mdi-checkbox-blank-circle-outline</v-icon>
								<span class="size-px-14 ml-10">{{ payment.subscrp_plan_name}}</span>
							</li>
						</ul>
					</div>
				</div>
				<div
					class="pa-20 mt-10 bg-white"
				>
					<div class="justify-space-between items-center" @click="onSpecial">
						{{ $language.subscribe.title_subscribe_advanced }} <!-- 고급설정 -->
						<v-icon>mdi-chevron-right</v-icon>
					</div>
					<div
						v-if="false"
						class="mt-20 justify-space-between" @click="onList"
					>
						{{ $language.subscribe.title_subscribe_load_text }} <!-- 글 불러오기 -->
						<v-icon>mdi-chevron-right</v-icon>
					</div>
				</div>
			</div>
		</div>
		<popup_select
			v-if="is_select"
			@cancel="is_select = false"
		>
			<template
				v-slot:title
			>{{  $language.notice.choice }}</template>
			<template
				v-slot:list
			>
				<template
					v-for="(item, index) in items_board_config"
				>
					<li
						v-if="item.board_type_code != 'CA00700001'"
						:key="'item_' + index"
						style="padding: 10px 0"
						@click="setBoard(item)"
					>{{ item.board_name }}</li>
				</template>
			</template>
		</popup_select>


		<Popup_confirm
			v-if="is_popup"
			@cancel="is_popup = false"
			@click="setVideo"
		>
			<template
				v-slot:title
			>{{  $language.common.video }}</template>
			<template
				v-slot:main-txt
			>
				{{ $language.interested.youtube_require }}
			</template>
			<template
				v-slot:sub-txt
			>
				<div
					v-for="i in video_size"
					:key="'i_' + i"
				>
					<input
						v-model="video[video_size - i]"
						type="text"
						style="border: 1px solid #ddd; padding: 10px"
						class="mt-10"
						placeholder="https://"
					/>
					<v-icon
						v-if="i == video_size"
						@click="setVideoSize(true, i)"
						class="ml-10 mt-5"
					>mdi-plus-circle-outline</v-icon>
					<v-icon
						v-else
						@click="setVideoSize(false, i)"
						class="ml-10 mt-5"
					>mdi-minus-circle-outline</v-icon>
				</div>
			</template>
		</Popup_confirm>
		<PopupLayer
			v-if="is_on_special"
		>
			<template
				v-slot:body
			>
				<div class="bg-white  radius-20 overflow-hidden">
					<div class="pa-20">
						<h3 class="pop_tit pop_tit_logo text-center under-line mt-30 pb-30">{{ $language.subscribe.title_subscribe_advanced }} <!-- 고급설정 --></h3>
						<div
							class=" mt-10 bg-white"
						>
							<div>
								<div
									class="justify-space-between items-center"
									@click="$set(item_setting, 'bdy_release_fg', item_setting.bdy_release_fg == 'Y' ? 'N' : 'Y')"
								>
									<h4 class="size-px-16">{{ $language.subscribe.title_subscribe_open }} <!-- 본문 공개 --></h4>
									<p class="toggle-group">
										<!-- TODO : 수신 허용시 .on 클래스 추가 -->
										<span
											class="btn-toggle"
											:class="{ on: item_setting.bdy_release_fg == 'Y' }"
										><em class="hide">{{  $language.common.dark_mode }}</em></span>
									</p>
								</div>
								<div class="mt-10 size-px-13 color-gray">{{ $language.subscribe.txt_subscribe_open }} <!-- 이 게시물의 본문을 숨길 경우 권한이 없는 멤버는 본문을 볼 수가 없습니다. --></div>
							</div>
							<div class="mt-20">
								<div
									class="justify-space-between items-center"
									@click="$set(item_setting, 'comment_release_fg', item_setting.comment_release_fg == 'Y' ? 'N' : 'Y')"
								>
									<h4 class="size-px-16">{{ $language.subscribe.title_subscribe_open_comment }} <!-- 댓글 공개 --></h4>
									<p class="toggle-group">
										<!-- TODO : 수신 허용시 .on 클래스 추가 -->
										<span
											class="btn-toggle"
											:class="{ on: item_setting.comment_release_fg == 'Y' }"
										><em class="hide">{{  $language.common.dark_mode }}</em></span>
									</p>
								</div>
								<div class="mt-10 size-px-13 color-gray">{{ $language.subscribe.txt_subscribe_open_comment }} <!-- 이 게시물의 댓글을 숨길 경우 권한이 없는 멤버는 댓글을 볼 수가 없습니다. --></div>
							</div>
						</div>
					</div>
					<div
						class="btn_wrap justify-space-between"
					>
						<button
							class=" btn_fill_gray"
							@click="resetSpecial"
						>{{ $language.common.close }} <!-- 닫기 --></button>
						<button
							class=" btn_fill_blue"
							@click="setSpecial"
						>{{  $language.common.ok }} <!-- 확인 --></button>
					</div>
				</div>
			</template>
		</PopupLayer>
		<Popup_confirm
			v-if="is_on_remove"
			@click="postRemoveFile"
			@cancel="offRemove"
		>

			<template v-slot:title>{{ $language.add.title_remove_file}} <!-- 첨부파일 삭제 --></template>
			<template
				v-slot:main-txt
			>{{ $language.add.txt_remove_file_main}} <!-- 해당 첨부파일을 삭제하시겠습니까?--></template>
			<template
				v-slot:sub-txt
			>{{ $language.add.txt_remove_file_sub}} <!-- 삭제된 파일은 복구되지 않습니다.--></template>

		</Popup_confirm>
	</div>
</template>

<style>
.m_video {	display: flex; flex-direction: column; justify-content: center; border: 1px solid #ddd; background-color: var(--bg-Gray02); border-radius: 10px; overflow: hidden}

.article_write_form_subject { font-size: 16px; display: flex; justify-content: space-between; padding: 10px}

.ct_textarea textarea { height: none; min-height: none;}

</style>

<script>

import Popup_confirm from "@/view/Layout/PopupConfirm";
import popup_select from "@/view/Layout/PopupSelect";
import draggable from 'vuedraggable'
import mafia_vote from '@/view/Cartel/mafiaVote'
import PopupLayer from "@/view/Layout/PopupLayer";
import {encodeStorage} from "@/resources/storage/localStorage";
import InputFileNew from "@/components/InputFileNew";

export default {
	name: 'mafia1272'
	, components: {InputFileNew, PopupLayer, Popup_confirm, popup_select, draggable, mafia_vote}
	, props:['user', 'cartel', 'board', 'bbs']
	, data: function(){
		return {
			program: {
				name: ''
				, title: ''
				, type: 'cartel_sub'
				, not_footer: true
				, not_header: true
			}
			, item_cartel: {}
			, item_board_config: {
				id: ''
			}
			, item_bbs: {
				code: this.$route.params.code
				, user_id: this.user.id
				, nick: this.user.nick
				, video:[]
				, vote: null
				, title: ''
				, content: ''
				, is_comment: true
			}
			, files: []
			, video: []
			, video_size: 1
			, items_board_config: []
			, is_select: false
			, bbs_id: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
			, is_popup: false
			, file_max: 8
			, is_vote: false
			, vote_fg: false
			, upload_files: []
			, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.cartel.cartl_number
			, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
			, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
			, board_type: this.$route.params.code ? this.$route.params.code : this.bbs.board_type_code
			, items_payment: []
			, is_on_special: false
			, is_on_list: false
			, items_list: []
			, item_list: {}
			, is_on_remove: false
			, post_appendix_file_indexnumber: 0
			, items_video: []
			, item_aws: {
				stremg_video_file_list: []
			}
			, item_setting: {
				bdy_release_fg: 'Y'
				, comment_release_fg: 'Y'
			}
		}
	}
	, computed: {
		is_disabled: function(){
			let t = false
			if(!this.item_bbs.content || this.item_bbs.content.trim() == ''){
				t = true
			}
			if(this.item_board_config.code == 'CA00700001' && (!this.item_bbs.title || this.item_bbs.title == '')){
				t = true
			}
			return t
		}
		, is_cheer: function(){
			let t = false
			if(this.item_board_config.board_type_code == 'CA00700002'){
				t = true
			}
			return t
		}
		, text_title: function(){
			let t = '글쓰기'

			if(this.bulletin_number){
				t = '글수정'
			}

			return t
		}
		, text_board_name: function(){
			let t = ''

			if(this.bulletin_number){
				t =  this.item_board_config.board_name
			}

			return t
		}
		, is_notice: function(){
			let t = false

			if(this.board_type == 'CA00700001'){
				t = true
			}

			return t
		}
		, vote_info: function(){
			if(this.vote_fg){
				return {
					vote_info: ''
					, vote_title: this.item_bbs.vote.title
					// , vote_startdt: this.item_bbs.vote.date + ' ' + this.item_bbs.vote.time.join(':')
					, vote_enddate: this.makeEnddate()
					, vote_type_code: this.item_bbs.vote.is_multiple ? 'CA01400002' : 'CA01400001'
				}
			}else{
				return {

				}
			}

		}
		, is_subscribe: function(){
			let t = false
			if(this.item_board_config.board_type_code == 'CA00700005'){
				t = true
			}
			return t
		}
		, post_subscrp_plan_list: function(){
			return this.items_payment.filter( (item) => {
				if(item.is_check){
					return {
						subscrp_plan_number: item.subscrp_plan_number
					}
				}
			})
		}
		, post_file_list: function(){
			return this.upload_files.filter( (item, index) => {
				console.log(index, item)
				item.post_appendix_file_change_before_indexnumber = item.post_appendix_file_indexnumber || 0
				item.post_appendix_file_indexnumber = index + 1
				item.post_file_url = item.src ? item.src : item.post_appendix_file_org_url
				return item
			})
		}
	}
	,methods: {
		getBoardConfig: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_config
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.cartl_number
						, board_number: this.board_number
					}
					, type: true
				})

				if(result.success){
					this.item_board_config = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postFiles: async function (){
			return true
		}
		, postFile: async function(appendix_file_div_code, file){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_file
					, data: {
						member_number: this.user.member_number
						, file_upload_code: 'CM00700015'
						, appendix_file_div_code: appendix_file_div_code
						, appendix_file_name: file
					}
					, multipart: true
					, type: true
				})

				if(result.success){
					return result.data.file_url
				}else{
					throw result.message
				}

			}catch (e) {
				console.log('postFile error', e)
				this.$bus.$emit('notify', { type: 'error', message: e})
				return false
			}
		}

		, postNotice: async function(){
			try {
				this.$bus.$emit('on', true)

				if(await this.postFiles()){
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_notice_modify
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.cartl_number
							, board_number: this.board_number
							, bulletin_number: this.bulletin_number
							, post_title: this.item_bbs.title
							, post_contents: this.item_bbs.content
							, post_file_list: this.post_file_list
							, file_appendix_fg: this.post_file_list.length > 0 ? 'Y' : 'N'
							// , vote_fg: this.vote_fg ? 'Y' : 'N'
							// , vote_info: this.vote_info
							// , vote_list: this.item_bbs.vote.items
							, comment_writing_fg: this.item_bbs.is_comment ? 'Y' : 'N'
						}
						, type: true
					})

					if(result.success){
						this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
						this.item_aws = result.data
						await this.postAws()
						await this.getCartelNoticeInfo()

					}else{
						throw result.message
					}
				}

			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postCheer: async function(){
			try {
				this.$bus.$emit('on', true)

				if(await this.postFiles()){
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cheer_modify
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.cartl_number
							, board_number: this.board_number
							, bulletin_number: this.bulletin_number
							, post_contents: this.item_bbs.content
						}
						, type: true
					})

					if(result.success){
						//this.$emit('to', { name: 'mafia053', params: { idx: this.cartl_number, code: this.board_type, b_id: this.board_number }})

						let item = {
							cartl_number: this.cartl_number
							, board_type: this.board_type
							, board_number: this.board_number
							, bulletin_number: this.bulletin_number
						}
						this.$emit('click', item)
					}else{
						throw result.message
					}
				}

			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, postBbs: async function(){
			try {
				this.$bus.$emit('on', true)

				if(await this.postFiles()){
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_bbs_modify
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.cartl_number
							, board_number: this.board_number
							, bulletin_number: this.bulletin_number
							, post_contents: this.item_bbs.content
							, file_appendix_fg: this.post_file_list.length > 0 ? 'Y' : 'N'
							, post_file_list: this.post_file_list
							//, vote_fg: this.vote_fg ? 'Y' : 'N'
							//, vote_info: this.vote_info
							//, vote_list: this.item_bbs.vote.items
						}
						, type: true
					})

					if(result.success){
						// this.$emit('to', { name: 'mafia049', params: { idx: this.cartl_number, code: this.board_type }})

						this.item_aws = result.data
						await this.postAws()
					}else{
						throw result.message
					}
				}

			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postSubscribe: async function(){
			try {
				this.$bus.$emit('on', true)

				if(await this.postFiles()){
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_subscribe_board_modify
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.cartl_number
							, board_number: this.board_number
							, bulletin_number: this.bulletin_number
							, post_contents: this.item_bbs.content
							, file_appendix_fg: this.post_file_list.length > 0 ? 'Y' : 'N'
							, post_file_list: this.post_file_list
							, bdy_release_fg: this.item_bbs.bdy_release_fg
							, comment_release_fg: this.item_bbs.comment_release_fg
							, post_subscrp_plan_list: this.post_subscrp_plan_list
						}
						, type: true
					})

					if(result.success){
						// this.$emit('to', { name: 'mafia049', params: { idx: this.cartl_number, code: this.board_type }})

						let item = {
							cartl_number: this.cartl_number
							, board_type: this.board_type
							, board_number: this.board_number
							, bulletin_number: this.bulletin_number
						}
						this.$emit('click', item)
					}else{
						throw result.message
					}
				}

			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getBoardList: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_menu
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.cartel.cartl_number
					}
					, type: true
				})

				if (result.success) {
					this.items_board_config = result.data.board_list
					if(!this.$route.params.b_id && !this.bbs.bulletin_number){
						this.$set(this, 'item_board_config', this.items_board_config[0])
					}
				} else {
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onBoard: function(){
			this.is_select = true
		}
		, setBoard: function(item){
			this.is_select = false
			this.item_board_config = item
			this.item_bbs.code = item.code
			console.log('this.item_board_config', this.item_board_config)
			//this.$emit('to', { name: this.$route.name, params: { idx: this.$route.params.idx, code: item.code}})
		}
		, onRemove: function(item, index){
			if(item.is_new){
				this.files.splice(index, 1)
				this.upload_files.splice(index, 1)
				this.post_file_list.splice(index, 1)
			}else{
				this.post_appendix_file_indexnumber = item.post_appendix_file_indexnumber
				this.is_on_remove = true
			}
		}
		, offRemove: function(){
			this.is_on_remove = false
		}
		, postRemoveFile: async function(){
			try{
				this.$bus.$emit('on', true)
				let url = this.$api_url.api_path.post_file_delete_n
				if(this.item_board_config.board_type_code == 'CA00700001'){
					url = this.$api_url.api_path.post_notice_file_delete
				}else if(this.item_board_config.board_type_code == 'CA00700005'){
					url = this.$api_url.api_path.post_file_delete
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
						, bulletin_number: this.$route.params.bbs_id
						, post_appendix_file_indexnumber: this.post_appendix_file_indexnumber ? this.post_appendix_file_indexnumber : 1
					}
					, type: true
				})

				if(result.success){
					this.offRemove()
					this.files = []
					this.upload_files = []
					this.item_bbs.post_file_list = result.data.post_file_list
					if(this.item_bbs.post_file_list.length > 0){
						for(let [key, val] of this.item_bbs.post_file_list.entries()){
							await this.getRealFile(val, key)
						}
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, removeVideo: function(index){
			this.video.splice(index, 1)
		}
		, getBbs: function(){

			switch (this.board_type){
				case 'CA00700001':
					this.getCartelNoticeInfo()
					break
				case 'CA00700002':
					this.getCartelCheerInfo()
					break
				case 'CA00700005':
					this.getCartelSubscribe()
					break
				default:
					this.getCartelBbs()
					break
			}
		}
		, getCartelBbs: async function(){
			try{
				if(!this.bbs_id){
					return
				}
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_bbs
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.cartl_number
						, board_number: this.board_number
						, bulletin_number: this.bulletin_number
					}
					, type: true
				})

				if(result.success){

					this.item_bbs = result.data
					this.board_type = this.item_bbs.board_type_code

					this.is_vote = result.data.vote_fg == 'Y' ? true : false
					this.vote_fg = result.data.vote_fg == 'Y' ? true : false

					this.item_bbs.content = this.item_bbs.post_contents
					this.$set(this.item_bbs, 'is_comment',  this.item_bbs.comment_writing_fg == 'Y' ? true : false)
					this.item_setting.bdy_release_fg = this.item_bbs.bdy_release_fg
					this.item_setting.comment_release_fg = this.item_bbs.comment_release_fg

					/*
					this.$refs.content.style.height = 'auto'
					this.$refs.content.style.height = this.$refs.content.offsetHeight + 'px'
					*/

					this.files = []
					this.upload_files = []
					if(this.item_bbs.post_file_list.length > 0){
						for(let [key, val] of this.item_bbs.post_file_list.entries()){
							console.log(key, val.post_appendix_file_org_url)
							val.src = val.post_appendix_file_org_url
							this.files.push({
								name: val.post_file_name
								, src: val.post_appendix_file_org_url
								, post_file_url: val.post_appendix_file_org_url
								, type: val.post_appendix_file_div_code == 'CA01000001' ? 'image' : val.post_appendix_file_div_code == 'CA0100002'? 'audio' : 'video'
							})
							this.upload_files.push(val)
						}
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}


		, getCartelSubscribe: async function(){
			try{
				if(!this.bbs_id){
					return
				}
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_subscribe_board_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.cartl_number
						, board_number: this.board_number
						, bulletin_number: this.bulletin_number
					}
					, type: true
				})

				if(result.success){

					this.item_bbs = result.data
					this.board_type = this.item_bbs.board_type_code

					this.is_vote = result.data.vote_fg == 'Y' ? true : false
					this.vote_fg = result.data.vote_fg == 'Y' ? true : false

					this.item_bbs.content = this.item_bbs.post_contents
					this.$set(this.item_bbs, 'is_comment',  this.item_bbs.comment_writing_fg == 'Y' ? true : false)
					this.item_setting.bdy_release_fg = this.item_bbs.bdy_release_fg
					this.item_setting.comment_release_fg = this.item_bbs.comment_release_fg

					/*
					this.$refs.content.style.height = 'auto'
					this.$refs.content.style.height = this.$refs.content.offsetHeight + 'px'
*/

					this.files = []
					this.upload_files = []
					if(this.item_bbs.post_file_list.length > 0){
						for(let [key, val] of this.item_bbs.post_file_list.entries()){
							await this.getRealFile(val, key)
						}
					}

					if(this.item_bbs.post_subscrp_plan_list.length > 0){
						this.setPlan(this.item_bbs.post_subscrp_plan_list[0])
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getCartelNoticeInfo: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_notice_info
					, data: {
						member_number: this.user.member_number

						, cartl_number: this.cartl_number
						, board_number: this.board_number
						, bulletin_number: this.bulletin_number
					}
					, type: true
				})

				if(result.success){

					this.item_bbs = result.data
					this.board_type = this.item_bbs.board_type_code

					this.is_vote = result.data.vote_fg == 'Y' ? true : false

					this.item_bbs.content = this.item_bbs.post_contents
					this.item_bbs.title = this.item_bbs.post_title
					this.$set(this.item_bbs, 'is_comment',  this.item_bbs.comment_writing_fg == 'Y' ? true : false)

					this.$refs.content.style.height = 'auto'
					this.$refs.content.style.height = this.$refs.content.offsetHeight + 'px'

					this.files = []
					this.upload_files = []
					if(this.item_bbs.post_file_list.length > 0){
						for(let [key, val] of this.item_bbs.post_file_list.entries()){
							console.log(key)
							this.files.push({
								name: val.post_file_name
								, src: val.post_file_url
								, post_file_url: val.post_file_url
								, type: val.post_appendix_file_div_code == 'CA01000001' ? 'image' : val.post_appendix_file_div_code == 'CA0100002'? 'audio' : 'video'
							})
							this.upload_files.push(val)
						}
					}

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getCartelCheerInfo: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_cheer_info
					, data: {
						member_number: this.user.member_number

						, cartl_number: this.cartl_number
						, board_number: this.board_number
						, bulletin_number: this.bulletin_number
					}
					, type: true
				})

				if(result.success){

					this.item_bbs = result.data
					this.board_type = this.item_bbs.board_type_code

					this.item_bbs.content = this.item_bbs.post_contents
					this.$set(this.item_bbs, 'is_comment',  this.item_bbs.comment_writing_fg == 'Y' ? true : false)

					this.$refs.content.style.height = 'auto'
					this.$refs.content.style.height = this.$refs.content.offsetHeight + 'px'
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onPopup: function(){
			this.is_popup = true
		}
		, setFile: function(type, e){

			let file_count = this.files.length + 1
			if(file_count > this.file_max){
				this.$bus.$emit('notify', { type: 'error', message: this.$language.common.error_file_limit})
				return false
			}

			let post_appendix_file_div_code = ''
			let file = e

			if(type.indexOf('video') > -1){
				post_appendix_file_div_code = 'CA01000002'
			}else if(type.indexOf('audio') > -1){
				post_appendix_file_div_code = 'CA01000003'
			}else{
				post_appendix_file_div_code = 'CA01000001'
			}

			let d = file.split('.')
			let t = d[d.length - 1]

			let contents_meta_data = {

				contents_type: t
				, contents_original_resolu_width: 0
				, contents_original_resolu_height: 0
				, contents_original_rolling_direction: 0
				,contents_length_seconds: '0'
			}


			this.upload_files.push({
				post_appendix_file_div_code: post_appendix_file_div_code
				, post_file_url: file
				, post_appendix_file_indexnumber: file_count
				, post_appendix_file_change_before_indexnumber: 0
				, src: file
				, is_new: true
				, contents_meta_data: contents_meta_data
			})
		}
		, setVideoFile: function(type){
			let file_count = this.files.length + 1
			if(file_count > this.file_max){
				this.$bus.$emit('notify', { type: 'error', message: this.$language.common.error_file_limit})
				return false
			}

			let post_appendix_file_div_code = ''
			// console.log(this.$refs['file_' + type].files)
			let file = this.$refs['file_' + type].files[0]
			file.index = file_count
			// console.log(file, e)

			const videourl = URL.createObjectURL(file);

			if(type.indexOf('video') > -1){
				post_appendix_file_div_code = 'CA01000002'

			}else if(type.indexOf('audio') > -1){
				post_appendix_file_div_code = 'CA01000003'
			}else{
				post_appendix_file_div_code = 'CA01000001'
			}
			this.upload_files.push({ type: type, src: videourl, post_file_url: videourl, file_name: file.name, post_appendix_file_div_code: post_appendix_file_div_code})
			this.files.push({ type: post_appendix_file_div_code, src: videourl,  post_file_url: videourl, file_name: file.name})

			let d = file.name.split('.')
			let t = d[d.length - 1]

			let contents_meta_data = {

				contents_type: t
				, contents_original_resolu_width: 0
				, contents_original_resolu_height: 0
				, contents_original_rolling_direction: 0
				,contents_length_seconds: '0'
			}

			file.contents_meta_data = contents_meta_data
			this.items_video.push(file)

			this.post_file_list.push({
				post_appendix_file_div_code: post_appendix_file_div_code
				, post_file_url: file
				, post_appendix_file_indexnumber: file_count
				, post_appendix_file_change_before_indexnumber: 0
				, src: file
				, is_new: true
				, contents_meta_data: contents_meta_data
			})
		}
		, setVideo: function(e){

			for(let video of e.target.files){
				const reader = new FileReader()
				let self = this
				reader.onload = function(e){
					self.video.unshift(e.target.result)
				}
				console.log('video ', video)
				reader.readAsDataURL(video)
			}
		}
		, handleClick(event, ref) {
			this.$refs[ref][event]();
		}
		, handleSearch(e, platform) {
			if (platform === "youtube") this.youtubeLink = e.target.value;
			else this.vimeoLink = e.target.value;
		}
		, setVideoSize: function(type, index){
			if(this.video_size > 5){
				return false
			}
			if(type){
				this.video_size++
			}else{
				this.video.splice(this.video_size - index, 1)
				this.video_size--
			}
		}
		, setVote: function(e){
			this.$set(this.item_bbs, 'vote', e)
		}
		, resetVote: function(){
			this.$bus.$emit('notify', { type: 'error', message: this.$language.notice.if_edit })
			return false
		}
		, removeVote: function(){
			this.item_bbs.vote = null
			this.is_vote = false
			this.vote_fg = false
		}

		, reSize: function(e){
			console.log('reSize', e)
			/*
			e.target.style.height = 'auto'
			e.target.style.height = e.target.scrollHeight + 'px'

			 */
		}
		, controlVideo: function(index){
			console.log('this.$refs video_' + index, this.$refs['video_' + index])
			this.$refs['video_' + index][0].play()
		}
		, doPost: function(){
			switch (this.board_type){
				case 'CA00700001':
					this.postNotice()
					break
				case 'CA00700002':
					this.postCheer()
					break
				case 'CA00700005':
					this.postSubscribe()
					break
				default:
					this.postBbs()
					break
			}
		}
		, makeEnddate: function(){
			return this.item_bbs.vote.date + ' ' + this.item_bbs.vote.time.join(':')
		}
		, toFile: function(){
			this.$bus.$emit('not_pause')
		}
		, goBack: function(){
			this.$emit('click', this.item_bbs)
			// this.$router.back()
		}
		, getPlan: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_subscribe_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.items_payment = result.data.subscrp_plan_list
					// this.items = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setPlan: function(item){
			this.items_payment.filter( (payment) => {
				if(item.subscrp_plan_number == payment.subscrp_plan_number){
					this.$set(payment, 'is_check', true)
				}else{
					this.$set(payment, 'is_check', false)
				}
			})
		}
		, onSpecial: function(){
			this.is_on_special = true
		}
		, offSpecial: function(){
			this.is_on_special = false
		}
		, setSpecial: function(){
			this.item_bbs.bdy_release_fg = this.item_setting.bdy_release_fg
			this.item_bbs.comment_release_fg = this.item_setting.comment_release_fg
			this.offSpecial()
		}
		, resetSpecial: function(){
			this.item_setting.bdy_release_fg = this.item_bbs.bdy_release_fg
			this.item_setting.comment_release_fg = this.item_bbs.comment_release_fg
			this.offSpecial()
		}
		, onList: function(){
			this.is_on_list = true
		}
		, offList: function (){
			this.is_on_list = false
		}
		, setList: function(item){
			this.item_list = item
			this.offList()
		}

		, getRealFile: async function(item, index){

			let domain = process.env.VUE_APP_DOMAIN
			let domain_m = process.env.VUE_APP_DOMAIN_M
			let domain_stg = process.env.VUE_APP_DOMAIN_STG
			let domain_stgm = process.env.VUE_APP_DOMAIN_STG_M
			let domain_dev = process.env.VUE_APP_DOMAIN_DEV
			let domain_devm = process.env.VUE_APP_DOMAIN_DEV_M
			let dev = process.env.VUE_APP_DEV
			let server = process.env.VUE_APP_SERVER
			let stg = process.env.VUE_APP_STG
			let local = process.env.VUE_APP_LOCAL
			let location = window.location.href

			let baseUrl = ''

			// 운영계 API 호출
			if(location.indexOf(domain) > -1 || location.indexOf(domain_m) > -1) {
				baseUrl = server

				// 검증계 API 호출
			}else if(location.indexOf(domain_stgm) > -1 || location.indexOf(domain_stg) > -1){
				baseUrl = stg

				// 개발계 API 호출
			}else if(location.indexOf(domain_dev) > -1 || location.indexOf(domain_devm) > -1){
				baseUrl = dev

				// 로컬 API 호출
			}else{
				baseUrl = local
			}

			let url = baseUrl + '/' + this.$api_url.api_path.get_board_file_stream
			let default_header = {
				'Content-Type': 'application/json'
				, 'v': '1.0.0'
				, 'Content-Security-Policy' : 'upgrade-insecure-requests'
			}

			let d = url.split('/::')
			if(d.length > 1){
				default_header.v = d[1]
				url = d[0]
			}

			let session_token = encodeStorage.getSessionToken()
			//console.log('session_token', session_token)

			if(session_token){
				default_header.a = session_token
				//console.log('default_header', default_header)
			}

			let data = {
				member_number: this.user.member_number
				, cartl_number: this.$route.params.idx ? this.$route.params.idx : item.cartl_number
				, board_number: this.$route.params.b_id ? this.$route.params.b_id : item.board_number
				, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : item.bulletin_number
				, file_upload_code: item.post_appendix_file_div_code
				, appendix_file_div_code: item.post_appendix_file_div_code
				, appendix_file_url: item.post_appendix_file_org_url
				, img_pay_div_code: item.post_appendix_file_img_pay_div_code
			}

			data = new URLSearchParams(data).toString()

			url += '?' + data

			await fetch(url, {
				headers: default_header
			})
				.then((response) => response.body)
				.then((body) => {
					this.$bus.$emit('on', true)
					const reader = body.getReader();

					return new ReadableStream({
						start(controller) {
							return pump();

							function pump() {
								return reader.read().then(({ done, value }) => {
									// When no more data needs to be consumed, close the stream
									if (done) {
										controller.close();
										return;
									}

									// Enqueue the next data chunk into our target stream
									controller.enqueue(value);
									return pump();
								});
							}
						},
					});
				})
				.then((stream) => new Response(stream))
				.then((response) => response.blob())
				.then((blob) => {

					let reader = new FileReader()
					reader.onload = (e) => {
						if(e.target.result){
							this.upload_files.push({
								post_appendix_file_div_code: item.post_appendix_file_div_code
								, post_file_url: e.target.result
								, post_appendix_file_indexnumber: index + 1
								, post_appendix_file_change_before_indexnumber: 0
								, src: e.target.result
							})
						}
					}

					reader.readAsDataURL(blob)
				})
				.catch(() => {
					this.$set(this.items, index, {
						file_url: 'error'
					})
				}).finally( () => {
					this.$bus.$emit('on', false)
				});

		}
		, onLoadMetadata: function(e, index){
			if(this.is_subscribe){
				return false
			}
			console.log('onLoadMetadata', e.target.videoWidth, e.target.videoHeight, e)
			let width = e.target.videoWidth
			let height = e.target.videoHeight
			let duration = e.target.duration
			let name = this.upload_files[index].file_name
			let d = name.split('.')
			let t = d[d.length - 1]
			this.$set(this.post_file_list[index], 'contents_meta_data', {
				contents_type: t // "콘텐츠타입", ->required   //png, mp4, mp3 등(현재는 미사용)
				, contents_original_resolu_width: width // ": 콘텐츠원본해상도너비, ->required   //동영상 또는 이미지의 너비(정수), 회전이 없는 컨텐츠일 경우 0
				, contents_original_resolu_height: height // ": 콘텐츠원본해상도높이, ->required   //동영상 또는 이미지의 높이(정수), 회전이 없는 컨텐츠일 경우 0
				, contents_original_rolling_direction: height > width ? 9 : 0 // ": 콘텐츠원본회전방향, ->required   //0(회전이 없는 컨텐츠), 1(회전 없음), 3(180도 회전), 6(시계 방향으로 90도 회전), 8(반시계 방향으로 90도 회전)
				, contents_length_seconds: duration + ''// ": 콘텐츠길이초 ->required
			})

			console.log('onLoadMetadata', this.post_file_list)
		}
		, postAws: async function(){
			try{
				this.$bus.$emit('on', true)
				let files = this.items_video;
				for(let i = 0; i < files.length; i++){
					let file = files[i]
					let item = this.item_aws.stremg_video_file_list[i]

					const result = await this.$Request({
						method: 'put'
						, url: item.stremg_video_file_url
						, header: {
							// 올리고자하는 동영상의 확장자로 설정 - test-video 파일의 경우 mp4
							"Content-Type": "video/" + file.contents_meta_data.contents_type,
							// "SHTP-02-001 숏플 게시물 등록 요청" - stremg_video_upload_header {key:value} 로 리퀘스트 헤더에 추가
							"x-amz-tagging": item.stremg_video_upload_header['X-Amz-Tagging'][0]
							, "amz-sdk-request" : ["attempt=1; max=3"]
						}
						, data: file
						, type: true
						, is_data: true
						, isResult: true
					})

					if(result.success){
						this.$bus.$emit('notify', { type: 'success', message: this.$language.shorts.txt_shorts_success2})
					}else{
						throw result.message
					}
				}
				if(this.item_board_config.board_type_code == 'CA00700001') {
					this.$emit('to', {name: 'mafia127',
						params: {
							idx: this.$route.params.idx,
							code: this.$route.params.code,
							b_id: this.$route.params.b_id
						}
					})
				}else{
					this.goBack()
					// this.$emit('to', { name: 'mafia053', params: { idx: this.$route.params.idx, code: this.item_board_config.board_type_code, b_id: this.item_board_config.board_number }})
				}

				this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})

			}catch (e) {
				console.log('postFile error', e)
				this.$bus.$emit('notify', { type: 'error', message: e})
				return false
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	,async created() {
		this.$bus.$emit('onLoad', this.program)
		/*
		await this.getCartel()
		await this.getBoardList()
		await this.getBoardConfig()
		*/

		// console.log('mafia127-2', this.bbs)

		await this.getCartel()
		await this.getBoardList()
		await this.getBoardConfig()
		await this.getPlan()
		await this.getBbs()
	}


}
</script>