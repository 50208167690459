<template>
	<Popup_select
		@cancel="$emit('cancel')"
		@click="toPayment"
		:is_disabled="is_disabled"
		:is_confirm="true"
	>
		<template
			v-slot:title
		>{{ $language.cartel_new.title_plan_choice }}<!-- 구독 플랜 선택 --></template>
		<template
			v-slot:list
		>
			<template
				v-if="items.length > 0"
			>
			<li
				v-for="(item, index) in list_plan"
				:key="'item_' + index"
				style="padding: 10px 0;"
				@click="setItem(item)"
				class="justify-space-between items-center"
				:class="{ 'on-subscribe': item.subscrp_plan_number == item_payment.subscrp_plan_number }"
			>
				<div class="overflow-hidden img-box-100 mr-10 radius-10 position-relative">
					<img :src="item.img_physl_path" @error="$bus.$emit('onErrorImage', $event)" class="object-cover"/>
					<div
						v-if="item.subscrp_plan_number == item_payment.subscrp_plan_number"
						class="position-absolute-full flex-column justify-center items-center"
					>
						<v-icon large class="color-blue-mafia">mdi-checkbox-marked-circle</v-icon>
					</div>
				</div>
				<div class="flex-1">

					<div class="mt-10 font-weight-500 size-px-14">{{ item.subscrp_plan_name }}</div>
					<div class="mt-10 size-px-16 font-weight-600 color-blue"><img :src="require('@/assets/image/icon_won_blue.svg')" /> {{ item.subscrp_amount | makeComma }}원</div>
				</div>
			</li>
			</template>
			<li
				v-else
				class="text-center"
			>
				{{ $language.add.txt_not_plan  }} <!-- 등록된 구독 플랜이 없습니다. -->
			</li>
		</template>
	</Popup_select>
</template>
<script>
import Popup_select from "@/view/Layout/PopupSelect";
export default {
	props: ['user', 'plan_info', 'cartel_number']
	, components: {Popup_select}
	, data: function(){
		return {
			items: []
			, item_payment: {}
		}
	}
	, computed: {
		list_plan: function(){
			return this.items.filter( (item) => {
				if(item.subscrp_plan_state_code == 'SS00200002'){
					return item
				}
			})
		}
		, is_disabled: function(){
			let t = true
			if(this.item_payment.subscrp_plan_number){
				t = false
			}
			return t
		}
	}
	, methods: {
		getData: async function(){
			try{
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_subscribe_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.cartel_number
						, subscrp_plan_number: this.plan_info.subscrp_plan_number ? this.plan_info.subscrp_plan_number :this.plan_info.payment_subscrp_plan_number
					}
					, type: true
				})

				if(result.success){
					this.items = result.data.subscrp_plan_list
					console.log(this.items_plane)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setItem: function(item){
			this.item_payment = item
		}
		, toPayment: function(){

			this.$bus.$emit('to',{ name: 'CartelSubscribeRequest', params: { idx: this.$route.params.idx ? this.$route.params.idx : this.cartel_number, p_id: this.item_payment.subscrp_plan_number, type: this.plan_info.subscrp_plan_number ? this.plan_info.subscrp_plan_number: this.plan_info.payment_subscrp_plan_number}})
		}
	}
	, created() {
		console.log('plan_info', this.plan_info)
		this.getData()
	}
}
</script>

<style>

.on-subscribe .img-box-100 {
	border: 1px solid var(--mafia-Blue)
}
</style>