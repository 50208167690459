<template>
	<div
		ref="post_list_item_view"
		style="min-height: 480px"
		class="full-height"
	>
		<template
			v-if="list_bbs.length > 0"
		>
			<div
				v-for="(item, index) in list_bbs"
				:key="'item_' + index"
				class="post_layout_view items"
				:class="{ '': item.is_on }"
			>
				<div class="contents_card">
					<article class="post_list_item_view">
						<!-- 게시글 작성자 -->
						<div class="post_author ">
							<div class="left">
								<a
									@click="on098(item)"
								>
									<span class="profile_inner">
										<img
											:src="$request.upload_url(item.profle_nft_card_img_url)"
											@error="$bus.$emit('onErrorImage', $event)"
										/>
									</span>
									<!-- 차단 -->
									<strong class="name">
										<em v-if="item.is_block" class="state-block">
											{{ $language.common.cut_off }}
										</em>
										{{ item.nickname ? item.nickname : item.post_member_nickname }}
									</strong>
								</a>
							</div>
							<div class="right">
								<span
									v-if="!item.is_tv"
									class="time"
								>{{ item.time_t }}</span>
								<button
									@click="onMore(item, index)"
									class="post_set "
								><v-icon class="color-gray02">mdi-dots-vertical</v-icon></button>
							</div>
						</div>

						<!-- //게시글 작성자 -->
						<!-- 게시글 메인 영역 -->
						<div
							v-if="!item.is_block"
							class="post_main_view mt-10"
						>
							<!-- 비주얼 영역 -->
							<div class="photo_view">
								<!-- 슬라이드 -->

								<div class="photo_collage_slide">

									<CartelBbsTv
										v-if="item.board_type_code == 'CA00700007' && item.post_file_list && item.post_file_list.length > 0 && item.post_file_list[1]"
										:user="user"
										:item="item"
										:index="index"
										:is_on="item.is_on"
										:paused="pause_all"
										:is_sample="is_sample"

										@toDetail="toDetail(item, index)"
									></CartelBbsTv>
									<CartelBbsFile
										v-else-if="item.post_file_list && item.post_file_list.length > 0"
										:user="user"
										:item="item"
										:index="index"
										:paused="pause_all"

										@toDetail="toDetail(item, index)"
									></CartelBbsFile>
								</div>
								<!-- 슬라이드 -->
							</div>
							<!-- //비주얼 영역 -->
							<!-- 텍스트 영역 -->
							<div
								v-if="item.vote_fg == 'Y'"
								class="text_view"
							>
								<button
									class="thumbnail_btn_s st_wjoin"

								>{{ $language.common.voting }}
								</button>
							</div>
							<div
								class="text_view"
								@click="toDetail(item, index)"
							>
								<div
									v-if="item.is_tv"
									class="text_more"
								>{{ item.time_t }}</div>
								<div class="mt-10 text_area">
									<v-icon v-if="item.is_lock_body" class="size-px-16 mr-5">mdi-lock</v-icon>
									<template v-if="item.board_type_code == 'CA00700007'">{{ item.title | maxLength(65, '...') }}</template>
									<template v-else>{{ item.contents | maxLength(65, '...') }}</template>
								</div>
								<div
									v-if="!item.is_tv && !item.is_block && !item.is_blur"
									class="text_more"
								>{{ $language.common.seeMore }} <!-- 더보기 --> </div>
							</div>
							<!-- //텍스트 영역 -->
						</div>
						<!-- //게시글 메인 영역 -->
						<!-- 게시글 공감 댓글 -->
						<div class="post_count_view">
							<div class="post_count">
								<div class="postcountleft">
									{{ $language.common.sympathy }} <span>{{ item.sympaty_count }}</span>명
								</div>
								<div class="postcountright" @click="toComment(item, index)">
									{{ $language.common.comment }} <span>{{ item.comment_count }}</span>개
								</div>
							</div>
							<div
								class="post_add" style="position: relative"
							>
								<div
									v-if="!item.is_tv"
									class="post_add_box">
									<div class="add_col">
										<!-- TODO : 공감 버튼을 누를경우 on 추가 -->
										<button
											@click="postEmoji(item, { code: 'CA01100001'})"
										><span class="add_status add_empathy " :class="{ on: item.is_empathy}">{{ $language.common.sympathy }} <!-- 공감 --></span>
										</button>
									</div>
									<div class="add_col">
										<a
											@click="toComment(item, index)"
										>
											<span class="add_status add_comment">
												{{ $language.common.comment }}
											</span>
										</a>
									</div>
								</div>

								<div
									v-if="item.is_emoji"
									class="emotion_view" style="top: -25px; left: 10px"
								>
									<div class="emotion_select_view">
										<div class="list" style="display: flex; padding: 5px">
											<!-- 선택된 표정의 버튼에는 on 클래스 추가 -->
											<button
												v-for="(emoji, index) in icons_emoji"
												:key="'emoji_icon_' + index"
												type="button" class="btn_emotionitem" data-emotion-type="best"
												:style="item.sympaty_icon_code == emoji.code ? 'background-color: #bbb; border-radius: 50px': ''"
												@click="postEmoji(item, emoji)"
											>
												<span
													class="faceicon_30  em_select_btn"
													:class="'f_' + emoji.type + '_30'"

												>{{ emoji.txt }}</span>
											</button>
										</div>
										<span class="list_arrow"></span>
									</div>
								</div>
							</div>
						</div>
						<!-- //게시글 공감 댓글 -->
					</article>
				</div>
			</div>
		</template>

		<Empty
			v-else
			class="size-px-16 full-height mt-150"
		>
		</Empty>

		<!-- 더보기 메뉴 -->
		<mafia064
			v-if="is_more"

			:user="user"
			:item_bbs="item_more"

			@cancel="is_more = false"
			@deleteBbs="deleteBbs"
			@move="moveBbs"
			@modify="toModify"
			style="z-index: 1003"
		>
		</mafia064>

		<!-- 구독 열람 권한 -->
		<popup_confirm
			v-if="is_on_subscribe_permission"
			@click="onSubscribe"
			@cancel="is_on_subscribe_permission = false"
		>
			<template v-slot:title>{{  $language.cartel_new.title_subscribe_permission }} <!-- 열람권한 확인 --> </template>
			<template
				v-slot:main-txt
			>{{  $language.cartel_new.txt_subscribe_permission_main }}  <!-- 열람권한이 없습니다. --></template>
			<template
				v-slot:sub-txt
			>{{  $language.cartel_new.txt_subscribe_permission_sub }}  <!-- 해당 게시판의 열람권한을 위해 구독 플랜을 구입하세요. --></template>
			<template
				v-slot:name-cancel
			>{{  $language.common.cancel }}</template>
			<template
				v-slot:name-confirm
			>{{  $language.cartel_new.title_subscribe }} <!-- 구독하기 --></template>
		</popup_confirm>

		<!-- 카르텔 구독 플랜 -->
		<CartelSubscribe
			v-if="is_on_subscribe"
			:user="user"
			:cartel_number="item_more.cartl_number"
			:plan_info="item_cartel.member_cartl_subscrp_plan_info"

			@cancel="offSubscribe"
		></CartelSubscribe>

		<!-- 회원 정보 -->
		<mafia098
			v-if="is_098"
			:cartel="item_cartel"
			:user="user"
			:friend="item_profile"

			@cancel="is_098 = false"
			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 9999; background-color: white"
		></mafia098>

		<!-- 게시물 상세보기 -->
		<cartel_bbs_detail
			v-if="is_item_detail"

			:user="user"
			:cartel="item_cartel"
			:bbs="item_more"
			:is_item_detail_comment="is_item_detail_comment"

			@cancel="update"
			@toModify="toModify"
			@deleteBbs="deleteBbs"
			@onModify="onModify"
			class="layer-popup"
		></cartel_bbs_detail>

		<!-- 댓글 목록 보기 -->
		<comment_list
			v-if="is_item_detail_comment"
			:user="user"
			:cartel="item_cartel"
			:bbs="item_more"

			@cancel="update"
			@goBack="toDetail(item_more, item_more.index)"
			class="layer-popup"
		></comment_list>

		<!-- 게시물 수정 -->
		<mafia1272
			v-if="is_on_item_modify"

			:user="user"
			:cartel="item_cartel"
			:bbs="item_more"

			@click="update"
			@cancel="offModify"

			class="layer-popup"
		></mafia1272>
	</div>
</template>

<script>

import Mafia064 from "@/view/Cartel/mafia064";
import Empty from "@/view/Layout/Empty";
import popup_confirm from "@/view/Layout/PopupConfirm";
import CartelSubscribe from "@/view/SubscribeCartel/CartelSubscribe";
import Mafia098 from "@/view/Cartel/mafia098";
import cartel_bbs_detail from "@/view/Cartel/cartel_bbs_detail";
import comment_list from "@/view/CartelComment/mafiaCommentList";
import Mafia1272 from "@/view/CartelBoard/mafia127-2";
import CartelBbsFile from "@/view/Cartel/CartelBbsFile";
import CartelBbsTv from "@/view/Cartel/CartelBbsTv";

export default {
	name: 'CartelBbsList'
	, components: {
		CartelBbsTv,
		CartelBbsFile,
		Mafia1272,
		comment_list,
		cartel_bbs_detail, Mafia098, CartelSubscribe, popup_confirm, Empty, Mafia064}
	, props: ['user', 'items_list', 'item_search', 'max', 'is_add', 'body', 'item_cartel', 'from', 'is_sample']
	, data: function(){
		return {
			is_more: false
			, is_delete: false
			, item_more: {}
			, is_item_detail: false
			, is_item_modify: false

			, icons_emoji: [

				{ code: 'CA01100001', type: 'best', txt: this.$language.emoticon.best}
				, { code: 'CA01100002', type: 'funny', txt: this.$language.emoticon.funny}
				, { code: 'CA01100003', type: 'like', txt: this.$language.emoticon.like}
				, { code: 'CA01100004', type: 'sad', txt: this.$language.emoticon.sad}
				, { code: 'CA01100005', type: 'surprise', txt: this.$language.emoticon.surprise}
				, { code: 'CA01100006', type: 'angry', txt: this.$language.emoticon.angry}
			]
			, is_on_subscribe_permission: false
			, is_on_subscribe: false
			, is_098: false
			, item_profile: {}
			, is_item_detail_comment: false
			, is_on_item_modify: false
			, is_scroll: false
			, timeout: ''
			, pause_all: false
		}
	}
	, computed: {

		list_bbs: function(){

			return this.items_list.filter( async (item) => {

				if(item.board_type_code == 'CA00700007'){
					item.is_tv = true
				}
				if(item.blok_member_fg == 'Y'){
					item.is_block = true
				}else{
					item.is_block = false
				}
				if(item.sympaty_icon_code != ''){
					item.is_empathy = true
				}
				item.time_t = this.$date.getTimeStory(item.registdate)

				item.contents = item.post_contents ? item.post_contents : item.contents

				if(item.board_type_code == 'CA00700005' && (item.member_post_subscrp_fg == 'N' || item.member_cartl_subscrp_fg == 'N')){
					item.is_blur = true
					if(item.bdy_release_fg != 'Y'){
						item.contents = '잠긴 컨텐츠'
						item.is_lock_body = true
					}
				}
				if(item.post_file_list && item.post_file_list.length > 0){
					for(let i = 0; i < item.post_file_list.length; i++){
						let file = item.post_file_list[i]
						// item.post_file_list[i].file_url = item.post_file_list[i].post_appendix_file_partial_list[0].appendix_file_url
						// file.file_url = file.post_appendix_file_org_url

						switch (file.appendix_file_div_code) {
							case 'CA01000001':
								file.is_image = true
								break;
							case 'CA01000002':
								file.is_video = true
								break;
							case 'CA01000003':
								file.is_audio = true
								break;
						}

						if(file.appendix_file_div_code){
							file.post_appendix_file_div_code = file.appendix_file_div_code
						}

						switch (file.post_appendix_file_div_code) {
							case 'CA01000001':
								file.is_image = true
								break;
							case 'CA01000002':
								file.is_video = true
								break;
							case 'CA01000003':
								file.is_audio = true
								break;
						}

						// console.log(item.bulletin_number, file.post_appendix_file_div_code, file.post_appendix_file_org_url)

						let p_files = file.post_appendix_file_partial_list
						if(p_files && p_files.length > 0){

							let p_file = file.post_appendix_file_partial_list[0]

							file.cartl_number = item.cartl_number
							file.board_number = item.board_number
							file.bulletin_number = item.bulletin_number
							file.appendix_file_div_code = file.post_appendix_file_div_code
							if(file.is_image){
								file.appendix_file_url = p_file.appendix_file_url + (p_file.appendix_file_name ? '/' + p_file.appendix_file_name : '')
							}else{
								file.appendix_file_url = p_file.appendix_file_url
							}
							file.img_pay_div_code = file.post_appendix_file_img_pay_div_code
						}else{

							file.cartl_number = item.cartl_number
							file.board_number = item.board_number
							file.bulletin_number = item.bulletin_number
							file.appendix_file_div_code = file.post_appendix_file_div_code

							if(file.file_url){
								file.post_appendix_file_org_url = file.file_url
							}
							let file_url = file.file_url ? file.file_url : file.post_appendix_file_org_url

							if(file.is_image) {
								file.appendix_file_url = file_url + (file.post_appendix_file_name ? '/' + file.post_appendix_file_name : '')
							}else{
								file.appendix_file_url = file_url
							}
							file.img_pay_div_code = file.post_appendix_file_img_pay_div_code

						}
					}

				}

				return item
			})
		}
	}
	, methods: {
		toDetail: function(item, index){

			if(!this.user.member_number){
				this.$bus.$emit('onLogin')
				return false
			}

			this.item_more = item
			this.$set(this.item_more, 'index', index)

			this.pause_all = false
			setTimeout(() => {
				this.pause_all = true
			}, 50)


			if(!item.is_block){
				if(item.board_type_code == 'CA00700005' && (item.member_post_subscrp_fg != 'Y' && item.member_cartl_subscrp_fg != 'Y')){
					// this.getCartel(this.$route.params.idx ? this.$route.params.idx : item.cartl_number)
					this.is_on_subscribe_permission = true
				}else{

					this.is_item_detail = false
					this.is_item_detail_comment = false

					let to_name = this.$route.name

					switch (this.$route.name){
						case 'index': case 'main': case 'cartel':
							to_name = 'home'
							break
					}

					this.$bus.$emit('to', { name: to_name,
						params: {
							idx: this.$route.params.idx ? this.$route.params.idx : item.cartl_number,
							code: this.$route.params.code ? this.$route.params.code : item.board_type_code,
							b_id: this.$route.params.b_id ? this.$route.params.b_id : item.board_number,
							bbs_id: this.$route.params.bbs_id ? this.$route.params.bbs_id : item.bulletin_number,
							type: this.$route.params.type
						},
						hash: 'detail'
					})
				}
			}
		}

		, toComment: function(item, index){
			if(!this.user.member_number){
				this.$bus.$emit('onLogin')
				return false
			}
			this.item_more = item
			this.$set(this.item_more, 'index', index)

			if(!item.is_block){
				if(item.board_type_code == 'CA00700005' && (item.member_post_subscrp_fg == 'N' || item.member_cartl_subscrp_fg == 'N') && item.comment_release_fg != 'Y'){
					this.is_on_subscribe_permission = true
				}else {

					this.is_item_detail = true
					this.is_item_detail_comment = true

					let to_name = this.$route.name

					switch (this.$route.name){
						case 'index': case 'main': case 'cartel':
							to_name = 'home'
							break
					}

					this.$bus.$emit('to', {name: to_name,
						params: {
							idx: this.$route.params.idx ? this.$route.params.idx : item.cartl_number,
							code: this.$route.params.code ? this.$route.params.code : item.board_type_code,
							b_id: this.$route.params.b_id ? this.$route.params.b_id : item.board_number,
							bbs_id: this.$route.params.bbs_id ? this.$route.params.bbs_id : item.bulletin_number,
							type: this.from
						},
						hash: 'comment'
					})
				}
			}
		}
		, onMore: function(item, index){
			if(this.user.member_number){
				this.is_more = true
				this.item_more = item
				this.$set(this.item_more, 'index', index)
			}else{
				this.$bus.$emit('onLogin')
			}
		}

		, deleteBbs: async function(){
			this.is_more = false
			this.is_item_detail = false
			this.items_list.splice(this.item_more.index, 1)
		}
		, moveBbs: async function(){
			this.is_more = false
			this.is_delete = false
			await this.mount()
		}
		, toModify: function(){

			let item = this.item_more
// console.log(item)
			let to_name = this.$route.name

			switch (this.$route.name){
				case 'index': case 'main': case 'cartel':
					to_name = 'home'
					break
			}

			this.$bus.$emit('to', {
				name: to_name,
				params: {
					idx: this.$route.params.idx ? this.$route.params.idx : item.cartl_number,
					code: this.$route.params.code ? this.$route.params.code : item.board_type_code,
					b_id: this.$route.params.b_id ? this.$route.params.b_id : item.board_number,
					bbs_id: this.$route.params.bbs_id ? this.$route.params.bbs_id : item.bulletin_number,
				}
				, hash: 'modify'
			})
		}
		, onModify: function(){
			this.is_item_modify = true
		}

		, postEmoji: async function(item, emoji){

			try{
				if(!this.user.member_number){
					this.$bus.$emit('onLogin')
					return false
				}
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_emoji
					, data: {
						member_number: this.user.member_number
						, cartl_number: item.cartl_number ? item.cartl_number : this.$route.params.idx
						, board_number: item.board_number
						, bulletin_number: item.bulletin_number
						, sympaty_icon_code: emoji.code
					}
					, type: true
				})

				if(result.success){
					item.is_empathy = emoji.code
					item.sympaty_icon_code = emoji.code
					item.is_emoji = false
					item.sympaty_count++
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_emoji = false
				this.$bus.$emit('on', false)
			}
		}
		, onSubscribe: function(){
			this.is_on_subscribe_permission = false
			this.is_on_subscribe = true
		}
		, offSubscribe: function(){
			this.is_on_subscribe = false
		}
		, on098: function(item){
			this.is_098 = true
			this.item_profile = item
		}

		, update: async function(item){

			if(this.item_more.bulletin_number){

				this.item_more.sympaty_icon_code = item.sympaty_icon_code
				this.is_item_detail = false
				this.is_item_detail_comment = false

				this.$router.back()
				let new_item = await this.getBbs(this.item_more)

				this.item_more.post_file_list = new_item.post_file_list
				this.item_more.sympaty_count = new_item.sympaty_list.length
				this.item_more.comment_count = new_item.comment_tot_count
				this.item_more.contents = new_item.contents
				this.item_more.post_contents = new_item.post_contents
				this.$emit('click', this.item_more)
			}
			if(this.from){
				this.$bus.$emit('to', this.from )
			}else{
				this.$bus.$emit('to', { name: this.$route.name})
			}
		}

		, getBbs: function(item){

			let bbs;
			switch (item.board_type_code){
				case 'CA00700001':
					bbs = this.getCartelNoticeInfo(item)
					break
				case 'CA00700002':
					bbs = this.getCartelCheerInfo(item)
					break
				case 'CA00700005':
					bbs = this.getCartelSubscribeInfo(item)
					break
				default:
					bbs = this.getCartelBbsInfo(item)
					break
			}

			return bbs
		}

		, getCartelBbsInfo: async function(item){
			try{
				if(!item.bulletin_number){
					return
				}
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_bbs
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: item.board_number
						, bulletin_number: item.bulletin_number
					}
					, type: true
				})

				if(result.success){
					return result.data

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getCartelSubscribeInfo: async function(item){
			try{
				if(!item.bulletin_number){
					return
				}
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_subscribe_board_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : item.cartl_number
						, board_number: this.$route.params.b_id ? this.$route.params.b_id : item.board_number
						, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : item.bulletin_number
					}
					, type: true
				})

				if(result.success){
					// console.log(item, result.data)

					return result.data

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getCartelNoticeInfo: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_notice_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.cartl_number
						, board_number: this.board_number
						, bulletin_number: this.bulletin_number
					}
					, type: true
				})

				if(result.success){

					return result.data

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getCartelCheerInfo: async function(item){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_cheer_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : item.cartl_number
						, board_number: this.$route.params.b_id ? this.$route.params.b_id : item.board_number
						, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : item.bulletin_number
					}
					, type: true
				})

				if(result.success){
					return result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toView: function(to){

			this.is_more = false
			this.is_item_modify = false
			this.is_on_item_modify = false
			this.is_item_detail = false
			this.is_item_detail_comment = false

			if(to.hash){
				switch (to.hash){
					case '#detail':
						this.is_on_item_modify = false
						this.is_item_detail = true
						this.is_item_detail_comment = false
						break
					case '#comment':
						this.is_on_item_modify = false
						this.is_item_detail = false
						this.is_item_detail_comment = true
						break
					case '#modify':
						this.is_on_item_modify = true
						this.is_item_detail = false
						this.is_item_detail_comment = false
						break
				}
			}
		}
		, offModify: function(){
			this.toDetail(this.item_more, this.item_more.index)
		}
		, setFile: function(){


		}

		, getRealFile_: async function(item){

			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'get'
					, url: this.$api_url.api_path.get_board_file_stream
					, data: {
						member_number: this.user.member_number
						, cartl_number: item.cartl_number
						, board_number: item.board_number
						, appendix_file_url: item.appendix_file_url
						, appendix_file_name: item.appendix_file_name
						, img_pay_div_code: item.img_pay_div_code
					}
					, type: true
					, isResult: true
					, blob: true
				})

				if(result.success){
					if(result.data.resultInfo){
						throw result.data.resultInfo.result_msg
					}else{

						let blob = (result.data).blob()

						let reader = new FileReader()
						reader.onload = (e) => {
							if(e.target.result){
								// console.log(e.target, e.target.result)
								this.$set(item, 'file_url', e.target.result)
							}else{
								this.$set(item, 'file_url', require('@/assets/image/@nophoto.png'))
							}
						}

						reader.readAsDataURL(blob)
					}
					// item.file_url = 'https://devappapi.mafinft.com/mafiaa/ssc/subscrppostappendixfileurldownload?member_number=M6520914238443440563&cartl_number=31238667920b24d&board_number=91047492835830028398&appendix_file_url=https:%2F%2Fmafia-d-sc-file.s3.ap-northeast-2.amazonaws.com%2FCM00700003%2F31238667920b24d%2F88201305981365079801%2FCA01000001%2FCM00300375&appendix_file_name=TWljcm9zb2Z0VGVhbXMtaW1hZ2UgKDE0KS5wbmc%3D_1.png&img_pay_div_code=CM01200002'
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				return require('@/assets/image/@nophoto.png')
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getCartel: async function(idx){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: idx
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
					}
					, type: true
				})

				if(result.success){
					// this.item_cartel = result.data
					this.is_on_subscribe_permission = true
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, onScroll: function(){

			this.is_scroll = true
			clearTimeout(this.timeout)
			this.timeout = setTimeout( () => {

				let ch = Math.ceil(document.body.clientHeight / 2)

				let view_top = (Number(this.$refs.post_list_item_view.getBoundingClientRect().top) - 50)
				view_top > 0 ?  view_top *= -1 : view_top
				let view_top2 = view_top + ch
				let d = document.getElementsByClassName('items')
				for(let [k, v] of Object.entries(d)) {
					let top = v.getBoundingClientRect().top + view_top
					let bottom = v.getBoundingClientRect().bottom + view_top
					if(top <= view_top2 && view_top2 <= bottom){
// console.log('done', k, view_top, view_top2, top, bottom)
						this.items_list.filter(( item, index) => {
							this.$set(item, 'is_on', false)
							if(k == index){
								this.$set(item, 'is_on', true)
							}
						})
						return
					}else{
						this.items_list.filter(( item) => {
							this.$set(item, 'is_on', false)
						})
// console.log(k, view_top, view_top2, top, bottom)
					}
				}
				this.is_scroll = false
			}, 500)
		}
	}

	, destroyed() {
		document.body.removeEventListener('scroll', this.onScroll)
	}
	, created() {
		// document.body.scrollTop = 0
		this.toView(this.$route)

		document.body.removeEventListener('scroll', this.onScroll)
		document.body.addEventListener('scroll', this.onScroll)
	}
	, watch:{
		$route(to, from){
			this.toView(to, from)
		}
	}
}
</script>

<style>
.box-size-slide { height: auto; min-height: 300px; max-height: 800px; overflow: hidden}

.post_set { background: unset !important;}
.bg_dark .VueCarousel-dot { background-color: var(--gray02) !important; }
.bg_dark .VueCarousel-dot.VueCarousel-dot--active { background-color: white !important; }
</style>