<template>
	<div class="justify-start items-center">
		<div
			class="icon color-black mr-10 cursor-pointer inline-block"
			:class="icon"

			@click="onProfile"
		>
			<img
				:src="img_src"
				@error="$bus.$emit('onErrorProfileImage', $event)"
				class="object-cover"
			/>
		</div>
		<div>
			<strong
				v-if="is_nickname"
				class="size-px-16 font-weight-500"
			>
				<em v-if="item_info.is_block" class="state-block">{{  $language.common.cut_off }}</em>

				{{ nickname }}
			</strong>
			<div
				v-if="is_cartel_name"
				@click="toCartel"
			>
				<span
					class="size-px-14 font-weight-400"
				>
					<em v-if="item_info.is_block" class="state-block">{{  $language.common.cut_off }}</em>

					{{ cartel_name }}
				</span>
			</div>
		</div>

		<mafia098
			v-if="is_on_profile"
			:cartel="cartel_info"
			:user="user"
			:friend="item_info"

			@cancel="is_on_profile = false"
			class="layer-popup"
		></mafia098>
	</div>
</template>

<script>

import Mafia098 from "@/view/Cartel/mafia098";
export default {
	name: 'ProfileImage'
	,
	components: {Mafia098},
	props: ['user', 'item_info', 'icon_type', 'cartel_info', 'is_nickname', 'is_cartel_name']
	, data: function(){
		return {
			is_on_profile: false
		}
	}
	, computed: {
		icon: function(){
			let t = 'icon-profile-'
			t += this.icon_type
			return t
		}
		, img_src: function(){
			let t = ''
			if(this.item_info.profle_nft_card_img_url){
				t = this.item_info.profle_nft_card_img_url

			}
			if(this.item_info.post_member_profle_nft_card_img_url){
				t = this.item_info.post_member_profle_nft_card_img_url
			}

			if(this.item_info.comment_member_profle_nft_card_img_url){
				t = this.item_info.comment_member_profle_nft_card_img_url
			}
			if(this.item_info.member_profle_nft_card_img_url){
				t = this.item_info.member_profle_nft_card_img_url
			}
			// this.$log.console('t', t)
			return t
		}

		, nickname: function(){
			let t = ''

			if(this.item_info.post_member_nick){
				t = this.item_info.post_member_nick

			}
			if(this.item_info.post_member_nickname){
				t = this.item_info.post_member_nickname
			}
			return t
		}
		, cartel_name: function(){
			let t = ''
			if(this.cartel_info.cartl_name){
				t = this.cartel_info.cartl_name
			}
			return t
		}
	}
	, methods: {
		onProfile: function(){
			this.is_on_profile = true
		}
		, toCartel: function(){
			this.$bus.$emit('to', {name: 'mafia049', params: {idx: this.cartel_info.cartl_number}})
		}
	}
	, created() {
		// console.log('profile_image', this.cartel_info)
		// this.$log.console('profile_image')
	}
}
</script>

<style>

.icon { border-radius: 100%; overflow: hidden}
.icon-profile-ss { width: 32px; height: 32px}
.icon-profile-s { width: 40px; height :40px;}
.icon-profile-m { width: 66px; height: 66px}
.icon-profile-l { width: 120px; height: 120px;}
.icon-profile-xl { width: 132px; height: 132px;}
</style>