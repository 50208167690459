<template>
	<div
		ref="wrap"
		class="position-relative overflow-hidden videojs"
	>
		<div class="full-height" style=" min-height: 150px;">
			<div
				v-show="is_view"
				ref="cover"
				:id="cover_id"
				class="position-absolute-full flex-column justify-center"
				style="z-index: 0;"
			>
				<img :src="item.cover.src" class="width-100" />
			</div>
			<div
				v-if="item.video.src"
				class="width-100 full-height position-relative flex-column justify-center"
				style="z-index: 1"
				:id="wrap_id"
			>
				<video
					v-if="is_video"
					:src="item.video.src"
					:id="video_id"
					crossorigin="use-credentials"

					@loadedmetadata="onLoadMetadata"
				></video>
				<div
					v-else
					ref="video"
					:id="video_id"
					class="width-100"
				>
				</div>
			</div>
		</div>
		<div
			v-if="is_custom"
			ref="control"
			class="position-absolute-full"
			style="z-index: 2"
		>
			<div
				class="full-height flex-column justify-center items-center"
				@click="pausePlay"
			>
				<span
					v-if="!item.video.paused"
					class="bg-black-half radius-100 pause"
				>
					<v-icon

						class="color-white " large
					>mdi-pause-circle-outline</v-icon>
				</span>
				<span
					v-else
					class="bg-black-half radius-100"
				>
					<v-icon
						class="color-white" large
					>mdi-play-circle-outline</v-icon>
				</span>
			</div>
			<div class="position-absolute pa-5-10 bg-black-half color-white radius-10 " :class="{ pause: !item.video.paused}" style="right: 10px; bottom: 10px"> {{ play_time }}</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'VideoJs'
		, props: ['item', 'id', 'controls', 'custom', 'pause', 'is_video', 'is_auto', 'play', 'is_sample']
		, data: function(){
			return {
				player: {

				}
				, is_cover_off: false
				, on_video: false
				, play_interval: ''
				, sample_time: 10
				, play_time: '00:00:00'
			}
		}
		, computed: {
			wrap_id: function(){
				let t = 'wrap_' + this.id
				return t
			}
			, video_id: function(){
				let t = 'video_' + this.id
				return t
			}
			, cover_id: function(){
				let t = 'cover_' + this.id
				return t
			}
			, is_view: function(){
				let t = true
				if(this.item.video.is_streaming){
					t = false
				}
				return t
			}
			, is_custom: function(){
				let t = false
				if(this.custom){
					t = true
				}
				if(this.controls && !this.is_sample){
					t = false
				}
				return t
			}
		}
		, methods: {

			loadVideo: function(){

				if(!this.item.video.src){
					return false
				}
				let file = this.item.video
				let src = file.src

				if(file.is_load) {
					if(this.player.paused()){
						if(this.is_auto){
							this.player.play()
							this.$set(this.item.video, 'paused', true)
						}
					}else{
						this.player.pause()
						this.$set(this.item.video, 'paused', false)
					}

					return false
				}

				if(this.is_video){
					this.player = document.getElementById(this.video_id)
					return false
				}
	// console.log('player initialize start')
				window.registerIVSTech(window.videojs)
				// IVS Player와 Video.js 통합 설정
				this.videoOptions = {
					techOrder: ["AmazonIVS"],
					autoplay: this.is_auto
					, playsinline: true
					, crossorigin: 'use-credentials'
					, restoreEl: true
					, loop: true
					, html5: {
						hls: {
							withCredentials: true
							, overrideNative: true
						}
						,dash: {
							setXHRWithCredentialsForType: ['MPD', true]
						}
					}
					, hls: {
						withCredentials: true
						, overrideNative: true
					}
					, withCredentials: true
					, origin: this.baseUrl
				};

				let video = document.createElement('video')

				video.onloadedmetadata = (e) => {
					this.onLoadMetadata(e)
				}
				let wrap_id = this.wrap_id
				let video_id = this.video_id
				let cover_id = this.cover_id

				video.setAttribute('id', video_id)

				document.getElementById(wrap_id).prepend(video)

				this.$set(file, 'is_load', true)
				let player = window.videojs(video_id, this.videoOptions, () => {
					// console.log('Player is ready to use');
				});

				this.player = player

				this.player.src({
					src:src
					,  withCredentials: true
				});
				setTimeout(() => {
					if(this.is_auto){
						this.player.play();
					}
					this.$set(this.item.video, 'paused', false)

					this.is_cover_off = true

					let video2 = document.getElementById(wrap_id).getElementsByTagName('video')[0]
					document.getElementById(wrap_id).innerHTML =''
					document.getElementById(wrap_id).prepend(video2)
					video2.id = video_id

					if(this.controls && !this.is_sample){

						setTimeout( () => {
							video2.controls = true
						}, 1000)
					}
				}, 100)

				// this.playerEvent = this.player.getIVSEvents().PlayerEventType;
				// this.player.getIVSPlayer().addEventListener(this.playerEvent.ERROR, this.loadVideoEvent, true);
				this.player.on('waiting', function () {
					console.log('Video is buffering...');
					// 버퍼링 상태에서 필요한 처리를 수행
				});

				this.player.on('canplay', function () {
					console.log('Video can start playing.');
					this.on_video = true
					// 버퍼링이 완료되고 재생이 시작 가능한 상태에서 필요한 처리를 수행
				});

				clearInterval(this.play_interval)
				this.player.on('playing', () => {
					console.log('Video is playing.');
					file.on_video = true
					// 비디오 재생이 시작될 때 필요한 처리를 수행
					let width = document.getElementById(wrap_id).clientWidth
					let height = document.getElementById(wrap_id).clientHeight
					console.log(width, height)
					document.getElementById(wrap_id).style.width = '100%'
					// document.getElementById('wrap-' + b).style.height = height + 'px'
					document.getElementById(cover_id).style.width = '100%'
					// document.getElementById('cover-' + b).style.height = height + 'px'
					let video = document.getElementById(video_id)
					console.log('duration', video.currentTime, video.duration)
					this.play_time = this.$date.transVideoTime(video.duration)
					this.play_interval = setInterval( () => {
						if(this.is_sample && video.currentTime >= this.sample_time){
							clearInterval(this.play_interval)
							this.setPause()
							video.currentTime = 0
						}
					}, 100)
				});

				this.player.on('stalled', function () {
					console.log('Video playback stalled.');
					file.on_video = false
					// 재생이 중단되거나 지연될 때 필요한 처리를 수행
				});

				// file.is_streaming = true

				// this.setSteamVideo(file, src)
			}
			, setSteamVideo: function(file, src){

				setTimeout( () => {
					if(this.player){
						this.player.src(src);
						this.player.play();
					}
				}, 300)
			}
			, setPause: function(){
				let d = this.player

				if(d){
					d?.pause()
					this.$set(this.item.video, 'paused', true)
				}
			}
			, setPlay: function(){
				let d = this.player

				if(d){
					d.play()
					this.$set(this.item.video, 'paused', false)
				}
			}
			, pausePlay: function(){
				let d = this.player

				if(d){
					if(this.item.video.paused){
						d.play()
						this.$set(this.item.video, 'paused', false)
					}else{
						d?.pause()
						this.$set(this.item.video, 'paused', true)
					}
				}else{
					this.loadVideo()
				}
			}
			, volumeMute: function(){
				let d = this.player
				d.muted(!d.muted())
				this.$set(this.item.video, 'muted', d.muted())
			}
			, onLoadMetadata: function(e){
				this.$emit('onLoadMetadata', e)
			}
		}

		, destroyed(){
			if(this.item.video.src){
				this.player?.dispose()
			}
		}
		, created() {
			// console.log('videojs', this.item)
			setTimeout( () => {
				this.loadVideo()
			}, 100)
		}
		, watch: {
			pause: {
				handler: function(call){
					if(call){
						this.setPause()
					}
				}
			}
			, play: {
				handler: function(call){
					if(call){
						this.setPlay()
					}
				}
			}
			, controls: {
				handler: function(call){
					if(this.player && !this.is_sample){
						let video2 = document.getElementById(this.video_id)
						video2.controls = call
					}
				}
			}
		}
	}
</script>

<style>
	.videojs video {
		width: 100%;
	}


	.videojs .pause{
		opacity: 0;
		transition: opacity 1s 1s ease
	}
</style>