<template>
	<div
		:class="{'bg-tv' : bbs.board_type_code == 'CA00700007'}"
	>
		<div class="header" >
			<div class="cartel_top">
				<div class="top top_mafi  f-left">
					<div class="util">
						<button
							@click="goBack"
							class="arrow_white"
						>
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area">
						<h2 class="page_tit">{{  bbs.cartl_name ? bbs.cartl_name : item_cartel.cartl_name }}</h2>
						<span class="page_subtit ">{{  item_board_config.board_name }}</span>
					</div>
				</div>
			</div>
		</div>
		<!-- //고정해더 -->
		<!-- 게시글 -->
		<div class="post_wrap pt-70 pb-50">
			<div class="container">
				<article class="post_list_item_view">
					<!-- 게시글 작성자 -->
					<div class="post_author justify-space-between items-center pa-10">
						<div class="left">
							<template
								v-if="is_notice"
							>
								<h2 class="mt-5" >{{ item_bbs.post_title }}</h2>
							</template>

							<ProfileImage
								v-else
								:user="user"
								:item_info="item_bbs"
								:cartel_info="item_cartel"
								icon_type="s"
								:is_nickname="true"
							></ProfileImage>
						</div>
						<div class="color-gray size-px-14 justify-space-between items-center">
							<span class="time mr-10">{{ getTimeStory('item_bbs', item_bbs.post_registdate)}}</span>
							<button
								@click="is_more = true"
								class="post_set"
							><v-icon>mdi-dots-vertical</v-icon></button>
						</div>
					</div>
					<!-- //게시글 작성자 -->
					<!-- 게시글 메인영역 -->

					<div
						v-if="files.length > 0"
						class="post_main_view mt-5"
					>
						<div class="photo_view">
							<!-- 이미지 -->
							<CartelBbsTv
								v-if="item_bbs.board_type_code == 'CA00700007' && item_bbs.post_file_list && item_bbs.post_file_list.length > 0 && item_bbs.post_file_list[1]"
								:user="user"
								:item="item_bbs"
								:is_on="item_bbs.is_on"

							></CartelBbsTv>
							<CartelBbsFile
								v-else
								:user="user"
								:item="item_bbs"

							></CartelBbsFile>
							<!-- //이미지 -->
						</div>
					</div>

					<div class="post_main_view">
						<!-- 비주얼 영역 -->
						<!-- //비주얼 영역 -->
						<div class="text_view original_text_view">
							<p class="text_area original_text color-gray-dark02" style="white-space: pre-line; word-break: break-all;">{{ item_bbs.post_contents }}</p>
						</div>
					</div>
					<!-- //게시글 메인영역 -->

					<mafia_vote
						v-if="item_bbs.vote_fg == 'Y'"
						:cartel="item_cartel"
						:vote="item_bbs.vote"
						:bbs="item_bbs"
						:user="user"

						type="vote"
						@click="item_bbs.vote_fg = 'N'; getBbs()"
					></mafia_vote>

					<!-- 공감하기 -->
					<mafia_emoji
						v-if="item_bbs.bulletin_number"
						:cartel="item_cartel"
						:bbs="bbs"
						:user="user"
						:list="items_emoji"

						@click="setEmoji"
					></mafia_emoji>
					<!-- //공감하기 -->

					<!-- 댓글영역 -->
					<div class="post_comment_main_view">
						<div class="flex-row justify-space-between">
							<span class="comment_count">{{ $language.common.comment }} <em class="count">{{  items_comment.length }}</em>개</span>
							<span class="comment_count">{{ $language.common.sympathy }} <em class="count">{{  items_emoji.length }}</em>개</span>
						</div>
						<!-- 댓글 -->
						<div
							v-for="(comment, index) in list_comment"
							:key="'comment_' + index"
							class="comment_layout"
						>
							<template
								v-if="comment.comment_level == 0"
							>
								<div
									v-if=comment.is_delete
									class="comment_view"
									v-long-press="long_press_time"
									@long-press-start="longPress(comment, index)"
									@long-press-stop="longPressStop(comment, index)"
								>
									<div class="item_wrap">
										<div class="write_user">
											<a
												class="u_profile"
											>
												<img
													:src="require('@/assets/image/@profile_inner.png')" alt="No Picture"
												>
											</a>
											<div class="write_desc">
												<strong class="name">{{  $language.common.text_delete_comment }}</strong>
											</div>
										</div>
										<div class="comment_body">
											<p style="word-break:break-all;"></p>
										</div>
									</div>
								</div>
								<div
									v-else
									class="comment_view"
									v-long-press="long_press_time"
									@long-press-start="longPress(comment, index)"
									@long-press-stop="longPressStop(comment, index)"
								>
									<div class="">
										<div class="size-px-14">
											<div class="justify-space-between items-center">
												<div class="justify-start items-center">
													<ProfileImage
														:user="user"
														:item_info="comment"
														:cartel_info="item_cartel"
														icon_type="ss"
													></ProfileImage>
													<div class="write_desc mr-10 size-px-16">
														<strong class="font-weight-500"><em v-if="comment.is_block" class="state-block">{{  $language.common.cut_off }}</em> {{  comment.comment_member_nickname }}</strong>
													</div>
												</div>
												<button
													@click="onPress(comment, index)"
													class="post_set"
												><v-icon>mdi-dots-vertical</v-icon></button>
											</div>
											<div class="justify-start items-center">
												<div class="icon-40"></div>
												<div class="flex-1">
													<p :inner-html.prop="comment.comment | nl2br" style="word-break:break-all;"></p>
													<img
														v-if="comment.comment_img_url"
														:src="$request.upload_url(comment.comment_img_url)"
														style="width: 100%; margin-top: 10px"
													/>
													<div class="mt-10 color-gray">
														<span class="time">{{  getTimeStory('comment', comment.registdate) }}</span>
														<button
															v-if="false"
															class="reply"  @click="toReply(item)"
														>{{ $language.common.write_reply }}</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</template>
							<!-- 재댓글 -->
							<div
								v-else
								class="replylist_view"
							>
								<div
									class=""
									v-long-press="long_press_time"
									@long-press-start="longPress(comment, index)"
									@long-press-stop="longPressStop(comment, index)"
								>
									<template
										v-if="comment.is_delete"
									>
										<div class="write_user">
											<a class="u_profile">
												<img
													:src="require('@/assets/image/@profile_inner.png')" alt="No Picture"
												>
											</a>
											<div class="write_desc">
												<strong class="name" >{{  $language.common.text_delete_comment }}</strong>
											</div>
										</div>
									</template>
									<template
										v-else
									>
										<div class="size-px-14">
											<div class="justify-space-between items-center">
												<div class="justify-start items-center">
													<ProfileImage
														:user="user"
														:item_info="comment"
														:cartel_info="item_cartel"
														icon_type="ss"
														:is_nickname="true"
													></ProfileImage>
													<div class="write_desc mr-10 size-px-16">
														<strong class="font-weight-500"><em v-if="comment.is_block" class="state-block">{{  $language.common.cut_off }}</em> {{  comment.comment_member_nickname }}</strong>
													</div>
												</div>
												<button
													@click="onPress(comment, index)"
													class="post_set"
												><v-icon>mdi-dots-vertical</v-icon></button>
											</div>
										</div>
										<div class="justify-start size-px-14">
											<div class="icon-40"></div>
											<div class="comment_body" style="position: relative">
												<button class="hashtag size-px-16" style="position: absolute; top: 3px; left: 0px"></button>
												<p style=""><span class="color-blue">&#64;{{ comment.comment_member_nickname}}</span> {{ comment.comment }}</p>
												<img
													v-if="comment.comment_img_url"
													:src="$request.upload_url(comment.comment_img_url)"

													class="mt-10 width-100"
												/>
												<div class="mt-10 color-gray">
													<span class="time">{{  getTimeStory('reply', comment.registdate) }}</span>
													<button
														v-if="false"
														class="reply" @click="$emit('to', { name: 'mafiaCommentList', params: { idx: $route.params.idx, code: $route.params.code, bbs_id: $route.params.bbs_id, reply_id: comment.comment_number}})"
													>{{  $language.common.write_reply }}</button>
												</div>
											</div>
										</div>
									</template>
								</div>
							</div>
							<!-- //재댓글 -->
						</div>
						<!-- 댓글 -->
					</div>
					<!-- //댓글영역 -->
				</article>

			</div>
		</div>
		<!-- //게시글 -->
		<!-- 댓글쓰기 -->
		<div class="comment_write">
			<div
				v-if="item_comment.img_src"
				class="photo_area"
			>
				<div class="photo_area_view">
					<img
						v-if="item_comment.img_src"
						:src="$request.upload_url(item_comment.img_src)"
					/>
				</div>
				<button
					@click="item_comment.img_src = ''"
					class="view_close">
					<i class="icon-close"></i>
				</button>
			</div>

			<div
				v-if="is_join"
				class="size-px-14 pa-10 box-shadow justify-space-between color-blue-mafia"
				@click="toCartel"
			>
				<div class="ml-10">
					<span class="color-blue font-weight-700">카르텔에 가입</span>하고 댓글을 남겨보세요!
				</div>
				<button
				><v-icon class="color-gray">mdi-chevron-right</v-icon></button>
			</div>
			<div
				v-else
				@click="toComment"
				class="write_area"
			>
				<div class="container">
					<h3 class="hide">{{  $language.common.comment_require }}</h3>
					<div class="write_main">
						<div class="btn_upload">
							<label for="albumFile" class="comment_albumfile" aria-label="Upload Image"></label>
						</div>
						<div class="mentions">
							<button
								v-if="item_comment.upper_id"
								class="hashtag"
							>&#64;{{ item_comment.upper_nick }} <v-icon small @click="clearReply">mdi-close</v-icon></button>
							<textarea
								v-model="item_comment.content"
								cols="20" :placeholder="$language.common.comment_require"
								:rows="rows" @focus="rows = 3" @focusout="rows=1"
							></textarea>

						</div>
						<div class="submit_wrap">
							<button
								type="submit" class="write_submit"
							></button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- //댓글쓰기 -->

		<mafia062
			v-if="is_062"

			:user="user"
			:cartel="cartel"
			:bbs="bbs"
			:item_comment="item_target"

			@cancel="is_062 = false"
			@click="getBbsComment"
			@modify="onModify"
			@delete="deleteComment"
			style="z-index: 1003"
		></mafia062>

		<mafia0632
			v-if="is_0632"

			:user="user"
			:cartel="item_cartel"
			:bbs="bbs"
			:comment="item_target"

			@cancel="is_0632 = false"
			@click="update"
			class="layer-popup"
		></mafia0632>

		<mafia064
			v-if="is_more"
			@cancel="is_more = false"
			@move="move"
			@deleteBbs="deleteBbs"
			@modify="toModify"

			:user="user"
			:item_bbs="item_bbs"
			style="z-index: 1003"
		>
		</mafia064>
	</div>
</template>

<script>
import mafia_vote from '@/view/Cartel/mafiaVote'

import mafia_emoji from '@/view/Cartel/mafiaEmoji'
import mafia064 from "@/view/Cartel/mafia064";
import Mafia062 from "@/view/Cartel/mafia062";
import Mafia0632 from "@/view/Cartel/mafia063-2";
import ProfileImage from "@/components/Daum/ProfileImage";
import CartelBbsFile from "@/view/Cartel/CartelBbsFile";
import CartelBbsTv from "@/view/Cartel/CartelBbsTv";

export default {
	name: 'cartel_bbs_detail'
	, props: ['user', 'cartel', 'board', 'bbs', 'is_item_detail_comment']
	, components: {CartelBbsTv, CartelBbsFile, ProfileImage, Mafia0632, Mafia062, mafia064, mafia_vote, mafia_emoji}
	, data: function(){
		return {
			program: {
				name: this.$language.notice.article_detail
				, title: this.$language.notice.article_detail
				, not_footer: true
				, not_header: true
				, type: 'cartel_sub'
				, from: this.$route.params.from
			}
			, item_cartel: {}
			, item_board_config: {}
			, item_bbs: {
				post_registdate: ''
				, vote_fg: 'N'
			}
			, items_comment: []
			, rows: 1
			, item_comment: {
				cartel_id: this.$route.params.idx ? this.$route.params.idx : this.bbs.cartl_number
				, bbs_code: this.$route.params.code
				, bbs_id: this.$route.params.bbs_id
				, user_id: this.user.id
				, user_nick: this.user.nick
				, user_img_src: this.user.img_src
				, upper_id: ''
				, upper_nick: ''
				, content: ''
			}
			, is_emoji: false
			, is_more: false
			, is_098: false
			, item_search: this.$storage.init({
				page_number: this.$language.base.page_number
				, pagerecnum: this.$language.base.pagerecnum
			})
			, item_update: {
				sympaty_icon_code: ''
			}
			, is_comment_view: this.is_item_detail_comment
			, items_emoji: []
			, item_profile: {}
			, long_press_time: 1000
			, is_062: false
			, item_target: {}
			, is_0632: false
			, items_file_subscribe: []
		}
	}
	, computed: {
		text_title: function(){

			let t = this.item_bbs.nick

			if(this.$route.params.code == 'CA00700001'){
				t = this.item_bbs.title
			}

			return t
		}
		, is_notice: function(){
			let t = false
			if(this.$route.params.code == 'CA00700001'){
				t = true
			}
			return t
		}
		, video: function(){
			let t = []
			if(this.item_bbs.video){
				if(this.item_bbs.video.length > 0){
					t = this.item_bbs.video
				}
			}
			return t

		}
		, files: function(){
			let t = []
			if(this.item_bbs.post_file_list){
				if(this.item_bbs.post_file_list.length > 0){
					t = this.item_bbs.post_file_list.filter( (item) => {
						item.post_file_url = item.post_appendix_file_org_url
						return item
					})
				}
			}
			return t

		}
		, sound: function(){
			let t = []
			if(this.item_bbs.sound){
				if(this.item_bbs.sound.length > 0){
					t = this.item_bbs.sound
				}
			}
			return t

		}
		, is_friend: function(){
			let t = false

			return t
		}
		, list_comment: function(){
			return this.items_comment.filter(function(item){
				if(item.blok_member_fg == 'Y'){
					item.is_block = true
				}
				return item
			})
		}
		, is_join: function(){
			let t = false
			if(this.item_cartel.cartl_entry_state_code == ''){
				t = true
			}
			return t
		}
	}
	, methods: {

		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.bbs.cartl_number
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getBoardConfig: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_config
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.bbs.cartl_number
						, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
					}
					, type: true
				})

				if(result.success){
					this.item_board_config = result.data
					// await this.getBbsComment()
				}else{
					if(result.code == 'E001201021'){
						this.$bus.$emit('notify', { type: 'error', message: result.message})
						this.$router.back()
					}
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getBbs: async function(){
			try{
				this.$bus.$emit('on', true)

				let url = this.$api_url.api_path.get_cartel_bbs_info
				if(this.$route.params.code == 'CA00700001'){
					url = this.$api_url.api_path.get_cartel_notice_info
				}else if(this.item_board_config.board_type_code == 'CA00700002'){
					url = this.$api_url.api_path.get_cartel_cheer_info
				}else if(this.item_board_config.board_type_code == 'CA00700005'){
					url = this.$api_url.api_path.get_subscribe_board_info
				}

				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.bbs.cartl_number
						, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
						, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
					}
					, type: true
				})

				if(result.success){
					this.item_bbs = result.data
					console.log('this.item_bbs', this.item_bbs)
					this.items_comment = result.data.comment_list
					this.items_emoji = result.data.sympaty_list
					this.$set(this.item_bbs, 'board_number', this.bbs.board_number)
					this.item_bbs.vote = result.data.vote_info
					if(this.item_bbs.vote){
						this.item_bbs.vote.vote_participation_people_count = result.data.vote_participation_people_count
						this.item_bbs.vote.time = result.data.vote_info.vote_end_daytime
						this.item_bbs.vote.items = result.data.vote_list
					}else{
						this.item_bbs.vote = {

						}
						this.item_bbs.vote.vote_participation_people_count = 0
						this.item_bbs.vote.time = ''
						this.item_bbs.vote.items = []
					}

					this.getSubscribeFiles(this.item_bbs)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getBbsComment: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_bbs_comment_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.bbs.cartl_number
						, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
						, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_comment = result.data.comment_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postComment: async function(){
			try{
				if(!this.item_comment.content || this.item_comment.content == ''){
					throw this.$language.common.comment_require_02
				}
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_comment
					, data: new Object(this.item_comment)
					, name: 'postComment'
				})

				if(result.success){
					if(this.item_comment.upper_id){
						for(let c of this.items_comment){
							if(c.id == this.item_comment.upper_id){
								console.log('c', c)
								if(!c.reply){
									c.reply = []
								}
								c.reply.push(result.data)
							}
						}
					}else{
						this.items_comment.unshift(result.data)
					}
					this.item_comment = {
						cartel_id: this.$route.params.idx ? this.$route.params.idx : this.bbs.cartl_number
						, bbs_code: this.$route.params.code
						, bbs_id: this.$route.params.bbs_id
						, user_id: this.user.id
						, user_nick: this.user.nick
						, user_img_src: this.user.img_src
						, upper_id: ''
						, upper_nick: ''
						, content: ''
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, clearReply: function(){
			this.item_comment.upper_id = ''
			this.item_comment.upper_nick = ''
		}
		, setReply: function(comment){
			this.item_comment.upper_id = comment.upper_id
			this.item_comment.upper_nick = comment.user_nick
			//this.item_comment.content = '@' + comment.user_nick + ' '
		}
		, getTimeStory: function(type, created_at){
			console.log(type, created_at)
			return this.$date.getTimeStory(created_at)
		}
		, setFile: function(e){
			console.log(e)

			const reader = new FileReader()
			let self = this
			reader.onload = function(e){
				self.$set(self.item_comment, 'img_src', e.target.result)
			}
			reader.readAsDataURL(e.target.files[0])
		}
		, toComment: function(){

			this.$bus.$emit('to', { name: this.$route.name, params: { idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id, bbs_id: this.$route.params.bbs_id, type: 'detail'}, hash: 'comment'})
		}
		, toReply: function(){
			this.is_comment_view = true
			this.$bus.$emit('to', { name: this.$route.name, params: { idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id, bbs_id: this.$route.params.bbs_id, type: 'reply'}, hash: 'reply'})
		}
		, move: async function(move_info){
			this.bbs.board_number = move_info.moving_board_number
			this.bbs.bulletin_number = move_info.bulletin_number

			this.is_more = false
			this.$bus.$emit('to', { name: 'mafia053', params: { idx: this.$route.params.idx ? this.$route.params.idx : this.bbs.cartl_number, code: move_info.moving_board_type_code, b_id: move_info.moving_board_number}})
		}
		, mount: async function(){
		}

		, deleteBbs: async function(){
			this.$emit('deleteBbs')
		}
		, goBack: function(){
			this.$emit('cancel', this.item_update)
		}
		, setEmoji: function(code){
			this.item_update.sympaty_icon_code = code
			this.items_emoji.push({
				sympaty_icon_code: "CA01100001"
				, sympaty_member_number: this.user.member_number
				, sympaty_member_profle_nft_card_img_url: "https://mafia-d-file.s3.ap-northeast-2.amazonaws.com/CM00700014/M6520914238443440563/M6520914238443440563.webp"
			})
		}
		, toModify: function(){
			this.$emit('toModify', this.item_bbs)
		}
		, on098: function(item){
			this.is_098 = true
			this.item_profile = item
			if(item.comment_member_number){
				this.item_profile.member_number = item.comment_member_number
			}
		}
		, longPress: function(item){
// console.log('longPress', item)
			item.is_long_press = true
		}
		, longPressStop: function(item, index){
			if(item.is_long_press){
				this.is_062 = true
				this.item_target = item
				this.item_target.index = index
			}
			item.is_long_press = false
		}
		, onPress: function(item, index){

			this.is_062 = true
			this.item_target = item
			this.item_target.index = index
		}
		, onModify: function(){
			this.is_062 = false
			this.is_0632 = true
		}
		, update: function(item){
			console.log('update', this.item_target, item)

			item.comment = item.content
			this.$set(this.items_comment, this.item_target.index, item)

			this.is_0632 = false
		}
		, deleteComment: function(){
			this.items_comment.splice(this.item_target.index, 1)
			this.is_062 = false
		}
		, getSubscribeFiles: function(item){
			if(item.post_file_list && item.post_file_list.length > 0){
				for(let i = 0; i < item.post_file_list.length; i++){
					let file = item.post_file_list[i]
					// item.post_file_list[i].file_url = item.post_file_list[i].post_appendix_file_partial_list[0].appendix_file_url
					// file.file_url = file.post_appendix_file_org_url

					switch (file.appendix_file_div_code) {
						case 'CA01000001':
							file.is_image = true
							break;
						case 'CA01000002':
							file.is_video = true
							break;
						case 'CA01000003':
							file.is_audio = true
							break;
					}

					switch (file.post_appendix_file_div_code) {
						case 'CA01000001':
							file.is_image = true
							break;
						case 'CA01000002':
							file.is_video = true
							break;
						case 'CA01000003':
							file.is_audio = true
							break;
					}

					console.log(item.bulletin_number, file.post_appendix_file_div_code, file.post_appendix_file_org_url)

					let p_file = file.post_appendix_file_partial_list[0]
					if(p_file){

						file.cartl_number = item.cartl_number
						file.board_number = item.board_number
						file.bulletin_number = item.bulletin_number
						file.appendix_file_div_code = file.post_appendix_file_div_code
						file.appendix_file_url = p_file.appendix_file_url + (p_file.appendix_file_name ? '/' + p_file.appendix_file_name : '')
						file.img_pay_div_code = file.post_appendix_file_img_pay_div_code
					}else{

						file.cartl_number = item.cartl_number
						file.board_number = item.board_number
						file.bulletin_number = item.bulletin_number
						file.appendix_file_div_code = file.post_appendix_file_div_code
						file.appendix_file_url = file.post_appendix_file_org_url + (file.post_appendix_file_name ? '/' + file.post_appendix_file_name : '')
						file.img_pay_div_code = file.post_appendix_file_img_pay_div_code

					}
				}

			}
		}
		, getSubscribeFile: async function(file){
			/**
			 * 이미지 해상도 코드
			 * CM00300001        0    원본
			 * CM00300050        0    58*58
			 * CM00300060        0    60*60
			 * CM00300064        0    64*64
			 * CM00300110        0    116*116
			 * CM00300120        0    120*120
			 * CM00300370        0    375*250
			 * CM00300750        0    750*500
			 * CM00301920        0    1920*300
			 */
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_bbs_comment_list
					, data: {
						member_number: this.user.member_number
						, file_upload_code: file.file_upload_code
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.bbs.cartl_number
						, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
						, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
						, appendix_file_div_code: file.appendix_file_div_code
						, img_resolu_code: 'CM00300750' // 750 * 500
						, img_pay_div_code: 'CM01200002'
						, file_partial_indexnumber: file.file_partial_indexnumber
						, appendix_file_name: file.appendix_file_name
					}
					, type: true
				})

				if(result.success){
					this.items_file_subscribe.push(result.data)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toList: function(){
			this.is_comment_view = false
			this.$emit('cancel')
		}
		, toCartel: function(){
			this.$bus.$emit('to', { name: 'mafia049', params: { idx: this.$route.params.idx ? this.$route.params.idx : this.item_cartel.cartl_number }})
		}
	}
	, async created() {

		await this.getCartel()
		await this.getBoardConfig()
		await this.getBbs()
		this.is_more = false
	}
}
</script>

<style>
</style>